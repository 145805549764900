import React from 'react';
import { Col, Row, Container} from 'react-bootstrap';

// layouts
import AppLayout from '../../layouts/AppLayout/AppLayout';

// component 
import { NioSection} from '../../components';



function index() {
  return (
    <AppLayout title="Coming Soon" layoutClass="3">

      {/*  Contact section end   */}

      {/*  CTA Section Start   */}
      <section className="nl-section nl-banner nl-banner-analytics overflow-hidden bg-primary-gradient-soft">
        <div className=" nl-banner-wrap pb-0 pb-md-8 overflow-hidden">
          <Container>
          <NioSection.Content className="position-relative overflow-hidden  bg-dark rounded-3 py-5 py-md-7" masks={["shape-31"]}>
          <Row className="justify-content-center">
            <Col lg="10" xl="9">
              <div className="d-flex flex-column align-items-center text-center gap-5 gap-lg-7">             
                  <h1 className="text-white mb-0">Coming Soon!</h1>      
                  <p className='text-white fs-5 opacity-80'>Estamos en proceso de desarrollo de la nueva página que estará disponible en breve. Agradecemos su paciencia y comprensión durante este período de actualización.</p>           
              </div>
            </Col>
          </Row>
        </NioSection.Content>
          </Container>
        </div>
      </section>

      {/*  CTA Section End   */}

      {/*  Newsletter Section Start  */}

      {/*  Newsletter Section End  */}
    </AppLayout >
  )
}

export default index;