import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

// layout
import AppLayout from '../../layouts/AppLayout/AppLayout';

// components 
import { NioSection } from '../../components';

// section content 
import ContactForm from '../../components/PageComponents/SectionComponents/ContactForm/ContactForm';
import CallToAction from '../../components/PageComponents/SectionComponents/CallToAction/CallToAction';

function index() {
  return (
    <AppLayout title="Contacto" layoutClass="3">

      <Helmet>
        <title>Contactanos: Completa nuestro formulario para resolver tus consultas | Upbirk</title>
        <meta name='description' content='Si tienes preguntas, necesitas más información o simplemente deseas compartir tus comentarios, no dudes en contactarnos.'/>
        <link rel='canonical' href='/Contacto'/>
      </Helmet>

      {/*  Support Section Start  */}
      <section className="nl-section nl-banner nl-banner-analytics overflow-hidden bg-primary-gradient-soft">
        <div className="nl-banner-wrap pb-0 pb-md-8 overflow-hidden pb-8">
          <Container>
            <Row className="justify-content-center m-auto">
              <Col lg="10" xl="8">
                <div className="nl-banner-content text-center">
                  <h3 className="display-4 m-0 pb-5">Contáctanos</h3>
                  <p className="fs-5">Si tienes preguntas, necesitas más información o simplemente deseas compartir tus comentarios, no dudes en contactarnos. Nuestro equipo está siempre disponible y listo para ofrecerte toda la ayuda que necesites.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <NioSection className="pt-0 pb-0">
        <NioSection.Content>
          <Row className="gy-5 justify-content-center">
            <Col lg="12">
              <ContactForm />
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Support Section End  */}

      {/*  CTA Section Start  */}
      <NioSection className="pt-8 pb-8">
        <NioSection.Content>
          <CallToAction />
        </NioSection.Content>
      </NioSection>
      {/*  CTA Section End  */}

      {/*  CTA Section End  */}

      {/*  Newsletter Section Start  */}

    </AppLayout >
  )
}

export default index;