import React from 'react'
import { Col, Row } from 'react-bootstrap'

// layout
import AppLayout from '../../layouts/AppLayout/AppLayout';

// components 
import { NioButton, NioSection} from '../../components';

function index() {
  return (
    <AppLayout title="404" layoutClass="3">
      <NioSection className="nl-section-error pt-120 pt-xl-180 overflow-hidden pb-0" masks={["blur-1 left top", "blur-1 right bottom"]}>
        <Row className="justify-content-center text-center">
          <Col lg="6" xl="8">
            <div className="pb-5 pb-lg-7">
              <div className="error-number mb-5" style={{color:"#3680BC"}}>404</div>
              <h2 className="m-0 mb-2 mb-md-4" >Oops, Pagina no encontrada</h2>
              <ul className="d-flex flex-wrap gap-3 pt-4 justify-content-center pt-3 pt-md-5 pb-2">
                <li>
                  <NioButton href="/" className="btn" label="Volver a Home" style={{backgroundColor:"#3680BC"}}/>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </NioSection>

    </AppLayout>
  )
}

export default index