import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';


// layouts
import AppLayout from '../../layouts/AppLayout/AppLayout';

// component 
import { NioMedia, NioSection, NioCard } from '../../components';

// section component
import CallToAction from '../../components/PageComponents/SectionComponents/CallToAction/CallToAction';


function index() {
  return (
    <AppLayout title="Amira tu Asistente Virtual Basada en IA" layoutClass="3">

      <Helmet>
        <title>Amira: tu Asistente Virtual Basada en IA | Upbirk</title>
        <meta name='description' content='Facilitamos la comprensión de los diferentes conceptos relacionados a las herramientas de nuestro sistema utilizando nuestro propio asistente virtual basado en IA'/>
        <link rel='canonical' href='/Amira'/> 
      </Helmet>

      {/*   Banner Section Start  */}
      <section className="nl-section nl-banner nl-banner-analytics overflow-hidden bg-primary-gradient-soft">
        <div className=" nl-banner-wrap pb-0 pb-md-8 overflow-hidden">
          <Container>
            <Row className="justify-content-center m-auto">
              <Col lg="10" xl="9">
                <div className="nl-banner-content text-center">
                  <div>
                    <h1 className="display-4 m-0 pb-5" >Potencia tu Proyecto con<span style={{color:"#3680BC"}}> AMIRA </span>Llevándolo al Siguiente Nivel</h1>
                    <p className="fs-5">
                      Facilitamos la comprensión de los diferentes conceptos relacionados a las herramientas de nuestro sistema utilizando nuestro propio asistente virtual basado en IA
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
              <Row className="justify-content-center">
              <Col xs="12" lg="10" xl="12" >
                <div className="pt-6 mt-md-2 pb-7 pb-md-0 w-100">
                  <div className="position-relative nl-banner-img nl-banner-img-s1">
                    <img className="thumb-base rounded-3 border" src="images/thumb/Frame 1400.svg"  alt="thumb-content"/>                   
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/*  Banner Section End   */}

      <section className="">
        <div className="">
          <Container>
            <Row className="justify-content-center ">
              <Col lg="10" xl="9">
                <div className="nl-banner-content text-center">
                  <h3 className="display-5 m-0 pb-5">Promoviendo un <span style={{color:"#3680BC"}}>auto-aprendizaje</span> eficaz en el desarrollo de tu proyecto de innovación.</h3>
                  <p className="fs-5">AMIRA es la asistente virtual de Upbirk impulsada por inteligencia artificial, guía a los emprendedores en el proceso de completar cada segmento del sistema y está representada por un avatar que busca fomentar una relación más estrecha con el usuario al ofrecer recomendaciones personalizadas, lo que reduce la necesidad de depender exclusivamente de mentores o de programas de innovación costosos, facilitando así un acompañamiento constante y accesible.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <NioSection className="overflow-hidden pt-8 pb-0" >
        <NioSection.Content>
          <Row className="gy-5">
            <Col md="6" lg="4" >
              <NioCard className="border-4 h-100">
                <NioCard.Body className="d-flex flex-column gap-5">
                  <NioMedia size="lg" rounded="circle" variant="blue-soft" icon="files-fill" style={{color:"#3680BC"}}/>
                  <div>
                    <h5>Asesoría Personalizada</h5>
                    <p className="fs-7 mb-0">Te dará consejos,para apoyarte en la realización de un framework.</p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="border-4 h-100">
                <NioCard.Body className="d-flex flex-column gap-5">
                  <NioMedia size="lg" rounded="circle" variant="blue-soft" icon="clock" style={{color:"#3680BC"}}/>
                  <div>
                    <h5>Comprensión de Frameworks</h5>
                    <p className="fs-7 mb-0">Te explicará que es el framework, para tener una compresión de que vas a completar.</p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="border-4 h-100">
                <NioCard.Body className="d-flex flex-column gap-5">
                  <NioMedia size="lg" rounded="circle" variant="blue-soft" icon="shield" style={{color:"#3680BC"}}/>
                  <div>
                    <h5>Recomendaciones y Consejos</h5>
                    <p className="fs-7 mb-0">Te dará recomedaciones de como poder realizar, cada modulo del framework.</p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="border-4 h-100">
                <NioCard.Body className="d-flex flex-column gap-5">
                  <NioMedia size="lg" rounded="circle" variant="blue-soft" icon="users" style={{color:"#3680BC"}}/>
                  <div>
                    <h5>Ejemplificación Práctica</h5>
                    <p className="fs-7 mb-0">Te dará ejemplos, utilizando empresas presentes en el mercado, ayudandote a entender de mejor manera.</p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="border-4 h-100">
                <NioCard.Body className="d-flex flex-column gap-5">
                  <NioMedia size="lg" rounded="circle" variant="blue-soft" icon="laptop" style={{color:"#3680BC"}}/>
                  <div>
                    <h5>Aceleración del Aprendizaje</h5>
                    <p className="fs-7 mb-0">
                      Disminuye el tiempo de aprendizaje de cada herramienta de trabajo.
                    </p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="border-4 h-100">
                <NioCard.Body className="d-flex flex-column gap-5">
                  <NioMedia size="lg" rounded="circle" variant="blue-soft" icon="layer" style={{color:"#3680BC"}}/>
                  <div>
                    <h5>Fluidez Operativa</h5>
                    <p className="fs-7 mb-0">Evita el estancamiento en cada frameworks, gracias a la información entregada en cada apartado.</p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>

      <section className="nl-section nl-banner nl-banner-analytics overflow-hidden">
        <div className="nl-banner-wrap pb-0 pb-md-8 overflow-hidden pt-8 pb-8">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="9">
                <div className="nl-banner-content text-center">
                  <h3 className="display-5 m-0 pb-5">Una tecnología en constante <span style={{color:"#3680BC"}}>Perfeccionamiento</span></h3>
                  <p className="fs-5">AMIRA está en constante entrenamiento por parte de nuestros ingenieros, los cuales utilizan la data generada en el sistema para ampliar el espectro de las recomendaciones del asistente virtual y a medida que más usuarios interactúan con el sistema, la capacidad de AMIRA para ofrecer consejos se afinará aun mas, mejorando la calidad y la pertinencia de la orientación proporcionada.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      {/*  CTA Section Start  */}
      <NioSection className="pt-0 pb-8">
        <NioSection.Content>
          <CallToAction />
        </NioSection.Content>
      </NioSection>
      {/*  CTA Section End  */}


    </AppLayout >
  )
}

export default index;