import React from 'react';
import { Col, Container, Row} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
// layouts
import AppLayout from '../../layouts/AppLayout/AppLayout';

// component 
import { NioButton, NioSection, NioCard} from '../../components';



function index() {
  return (
    <AppLayout title="Incubadora Corporativa Potenciada por IA" layoutClass="3">

      <Helmet>
        <title>Lab2: Incubadora Corporativa Potenciada por IA | Upbirk</title>
        <meta name='description' content='El complemento perfecto para el desarrollo de tu proyecto de innovación'/>
        <link rel='canonical' href='/Lab2'/>
      </Helmet>

      {/*   Banner Section Start  */}
      <section className="nl-section nl-banner nl-banner-dg-business bg-primary-gradient-soft pb-8">
        <div className="nl-banner-wrap pb-0">
          <Container>
            <div className="nl-banner-content  px-5 px-md-7 px-lg-0  py-7 py-md-120 position-relative bg-gradient-16 rounded-3 overflow-hidden">
              <div className="nl-mask"></div>
              <Row className="align-items-center justify-content-center pb-xl-3">
                <Col lg="10" xxl="9">
                  <div className="text-center">
                    <div className="nl-section-head text-center">
                      <h1 className="display-4 text-white m-0 pb-5">Laboratorio de I+D en Innovación Empresarial</h1>
                      <p className="fs-5 text-white opacity-75">
                      Mediante ciencia, tecnología y conocimiento experto, impulsamos la transformación del conocimiento generado en laboratorios en negocios viables y escalables
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </section>

      <NioSection className="pt-0 pb-8">
        <NioSection.Content className="d-flex flex-column gap-7">
          <Row className="align-items-start flex-lg-row-reverse justify-content-between ">
            <Col lg="6" xl="5">
              <div className="nl-feature-overview-img text-center mb-5 mb-lg-0">
              <img src="images/team/ImagenHome.png" className="rounded-4" alt="thumb" style={{width:"450px"}}/>
                <div className="children children-center w-100 h-100">
                </div>
              </div>
            </Col>
            <Col lg="5">
              <div>
                <div className="nl-section-head pb-5">
                  <h2 className="nl-section-title">¿Qué es <span style={{color:"#3680BC"}}>Lab2</span>?</h2>
                </div>
                <ul className="nl-timeline d-flex flex-column gap-3 gap-md-5">
                  <li className="nl-timeline-item p-0">
                    <div className="nl-timeline-item-inner">   
                      <div className="nl-timeline-content mt-n1" style={{textAlign:"justify"}}>
                        <p>LAB2 es un laboratorio de investigación e incubación que busca transformar cómo la innovación empresarial se desarrolla mediante el conocimiento experto, ciencia y tecnología. El laboratorio tiene como visión convertirse en un punto de referencia en los usos de la Inteligencia artificial enfocadas a agilizar el desarrollo de ideas de negocios durante las diferentes etapas de su ciclo de vida. Nuestro objetivo es cambiar la forma en la cual se percibe la innovación empresarial, promoviendo el uso de herramientas digitales que fomenten la transformación de startups científico-tecnológicas en negocios viables y escalables.</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="align-items-start justify-content-between">
            <Col lg="6" xl="5">
              <div className="nl-feature-overview-img text-center mb-5 mb-lg-0">
              <img src="images/team/foto-2.png" className="rounded-4" alt="thumb"  style={{width:"450px"}}/>
                <div className="children children-center w-100 h-100">
                </div>
              </div>
            </Col>
            <Col lg="5">
              <div>
                <div className="nl-section-head pb-5">
                  <h2 className="nl-section-title">Quienes <span style={{color:"#3680BC"}}>somos</span></h2>
                </div>
                <ul className="nl-timeline d-flex flex-column gap-3 gap-md-5">
                  <li className="nl-timeline-item p-0">
                    <div className="nl-timeline-item-inner">
                      <div className="nl-timeline-content mt-n1" style={{textAlign:"justify"}}>
                        <p>LAB2 nace formalmente en 2023 en base a la una respuesta a la creciente demanda de por parte de clientes por servicios de incubación más integrales que además de la asesoría experta, integren tecnología que faciliten el desarrollo de nuevos proyectos de innovación. Adoptamos una perspectiva interdisciplinaria basada en la colaboración entre expertos en innovación y negocios, científicos sociales, investigadores e ingenieros para desarrollar el conocimiento necesario para explorar oportunidades comerciales impulsadas por la Inteligencia Artificial en el campo de la innovación empresarial.</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>

      {/*  Banner Section End   */}
      <NioSection className="py-7  pt-0 pb-0">
        <NioSection.Content>
        <NioSection.Head alignX="center" className="p-0">
          <h1 className="display-5 pb-5">Para quien es <span style={{color:"#3680BC"}}>Lab2</span></h1>
        </NioSection.Head>
          <Row className="gy-5 gy-lg-0">
            <Col md="6" lg="4" >
              <NioCard className="bg-transparent border-0 h-100">
                <div className="card-img-top border rounded-4 bg-gray-400">
                  <img src="images/team/img-our-clients-1.png" alt="cover-img" className="rounded-4" style={{height:"365px"}}/>
                </div>
                <NioCard.Body className="px-0 pt-4">
                  <h5 className='text-center'>Corporate</h5>
                  <p className="fs-7 mb-0" style={{textAlign:"justify"}}>Apoyamos a grandes empresas que necesiten acompañamiento estratégico y asesoría especializada, enriqueciendo su desarrollo con nuestro knowhow y tecnología.</p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="bg-transparent border-0 h-100">
                <div className="card-img-top border rounded-4 bg-gray-400">
                  <img src="images/team/img-our-clients-3.png" alt="cover-img" className="rounded-4" style={{height:"365px"}}/>
                </div>
                <NioCard.Body className="px-0 pt-4">
                  <h5 className='text-center'>Startups</h5>
                  <p className="fs-7 mb-0" style={{textAlign:"justify"}}>Asesoramos a Startups y Emprendedores que buscan desarrollar sus ideas en negocios, proporcionamos acceso a nuestra tecnología, sumado a la experiencia acumulada y una sólida red de apoyo.</p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="bg-transparent border-0 h-100">
                <div className="card-img-top border rounded-4 bg-gray-400">
                  <img src="images/team/img-our-clients-2.png" alt="cover-img" className="rounded-4" style={{height:"365px"}}/>
                </div>
                <NioCard.Body className="px-0 pt-4">
                  <div>                   
                  <h5 className='text-center'>Researchers</h5>
                  <p className="fs-7 mb-0" style={{textAlign:"justify"}}>Trabajamos con investigadores y académicos a cómo transformar sus proyectos de laboratorio en empresas viables, aprovechando el nuestro conocimiento, tecnología y redes de contacto en la industria.</p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  CTA Section End   */}
      <NioSection className="pb-8 pt-8">
    <NioSection.Content className="nl-section-content px-5 px-xl-0 py-7 position-relative rounded-4 mb-0" style={{backgroundColor:"#deefe7"}}>
      <Row className="position-relative gy-5 gy-lg-7 flex-column align-items-center justify-content-center" >
        <Col xl="8">
          <div className="text-center">
            <h2 className="m-0 pb-5">¿Interesado en explorar como LAB2 puede ayudar a tu organización?</h2>
            <p className='fs-5 m-0'>Ingresa directamente a Lab2 y descubre como podemos ayudarte</p>
        <NioButton href="https://www.lab2.co/" className="btn mt-3" label="Ir a Lab2" target="_blank" style={{backgroundColor:"#fdc24c"}}/>
          </div>
        </Col>
      </Row>
    </NioSection.Content>
  </NioSection>
    </AppLayout >
  )
}

export default index;