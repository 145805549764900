import React from 'react';
import { Col, Row } from 'react-bootstrap';

// layout 
import AppLayout from '../../layouts/AppLayout/AppLayout';

// section content 
import { NioSection } from '../../components';
import CallToAction from '../../components/PageComponents/SectionComponents/CallToAction/CallToAction';

function index() {
  return (
    <AppLayout layoutClass="3" title="Politica de Cookies">

      {/*  Terms Section Start  */}
      <NioSection className="pt-120 pt-lg-180">
        <div className="nl-block-head md">
          <div className="nl-section-head pb-0">
            <h2 className='text-center'>Politica de Cookies</h2>
          </div>
        </div>
        <NioSection.Content>
          <Row className="pt-3 justify-content-center">
            <Col lg="8">
              <div className="nl-entry-wrap pt-3 ps-lg-5 ">
                <div className="nl-entry " style={{textAlign:"justify"}}>
                  <div className="pb-5">
                    <h5>Términos y Condiciones</h5>
                    <p className="fs-7 text-dark">Esta política detalla la práctica de INNOVACIONES Y DESARROLLOS TECNOLÓGICOS UPBIRK SPA con respecto a la colocación, uso y administración de "cookies" utilizadas por el dominio upbirk.com y la aplicación UPBIRK (en adelante, brevemente, genéricamente, UPBIRK, la Aplicación, respectivamente, el Sitio web o el Sitio) y tiene como objetivo informar a los usuarios sobre este tema. Al utilizar el Sitio y/o la Aplicación, los usuarios reconocen que han tomado conocimiento y aceptan esta Política de Cookies, así como la Política de Privacidad y Protección de Datos y también los Términos y Condiciones publicados en el Sitio y en la Aplicación. </p>
                    <p className="fs-7 text-dark">
                    Última actualización: 08 de febrero de 2024 
                    </p>
                    <p className="fs-7 text-dark fw-bold">
                    Identificación del responsable del tratamiento de datos 
                    </p>
                    <p className="fs-7 text-dark">
                    <b>Nombre:</b> INNOVACIONES Y DESARROLLOS TECNOLÓGICOS UPBIRK SPA (en adelante, la Empresa, el Propietario o UPBIRK SPA)    
                    </p>
                    <p className="fs-7 text-dark">
                    <b>Dirección:</b> 1 NORTE 461 OF 703 VERGARA VINA DEL MAR, CHILE.    
                    </p>
                    <p className="fs-7 text-dark">
                    <b>Rut:</b> 77.381.573-9    
                    </p>
                    <p className="fs-7 text-dark">
                    <b>Correo electrónico:</b> office@upbirk.com    
                    </p>
                  </div>
                  <div className="pb-5">
                    <h5>Detalles de contacto en el ámbito de la protección de datos personales </h5>
                    <p className="fs-7 text-dark">Los detalles de contacto que el usuario puede utilizar para transmitir cualquier solicitud, notificación o reclamo con respecto a los Términos y condiciones, la Política de privacidad y protección de datos, esta Política de cookies, así como cualquier otra información publicada en el Sitio o la Aplicación, políticas u operaciones realizadas por la Empresa, se indican en el punto 1 anterior.</p>
                    <p className="fs-7 text-dark">El plazo para que la Empresa envíe una respuesta es de no más de 30 días a partir de la recepción de la solicitud.</p>
                  </div>
                  <div className="pb-5">
                    <h5>Información </h5>
                    <p className="fs-7 text-dark m-1">El Sitio y la Aplicación utilizan cookies propias y de terceros para proporcionar a los usuarios una experiencia de navegación mucho mejor y servicios adaptados a sus necesidades e intereses.</p>
                  </div>
                  <div className="pb-5">
                    <h5>Propósito de las cookies</h5>
                    <p className="fs-7 text-dark m-1">El papel de las cookies es: </p>
                    <p className="fs-7 text-dark m-1">- proporcionar a los propietarios de sitios y aplicaciones (implícitamente y a la Empresa) una retroalimentación valiosa sobre cómo sus plataformas son utilizadas por los visitantes para que puedan hacerlas aún más eficientes y accesibles para los usuarios;</p>
                    <p className="fs-7 text-dark m-1">- permitir que aplicaciones multimedia u otras aplicaciones de otros sitios/aplicaciones se incluyan en su propio sitio/aplicación, para crear una experiencia de navegación más valiosa, útil y agradable;</p>
                    <p className="fs-7 text-dark m-1">- mejorar la eficiencia de la publicidad en línea. </p>
                    <p className="fs-7 text-dark m-1">¡Las cookies NO son virus! Utilizan formatos de texto plano. No están formadas por fragmentos de código, por lo que no pueden ejecutarse ni autoejecutarse. Como resultado, los usuarios no pueden duplicar ni replicar en otras redes para ejecutar o replicar nuevamente. Debido a que no pueden realizar estas funciones, no pueden considerarse virus. Sin embargo, las cookies pueden usarse con fines negativos. Como almacenan información sobre las preferencias de navegación del usuario y el historial de navegación, las cookies pueden usarse como spyware.</p>
                  </div>
                  <div className="pb-5">
                    <h5>Definición de cookies </h5>
                    <p className="fs-7 text-dark m-1">El término "cookie" se refiere a las tecnologías mediante las cuales se recopila cierta información automáticamente. Una "Cookie de Internet" (también conocida como "cookie del navegador" o "cookie HTTP" o simplemente "cookie") es un archivo pequeño, compuesto por letras y números, que se almacenará en su computadora, dispositivo móvil u otro equipo de un usuario que accede a Internet. </p>
                    <p className="fs-7 text-dark m-1">La cookie se instala por solicitud de un servidor web desde un navegador (por ejemplo, Internet Explorer, Chrome) y es completamente "pasiva" (no contiene software, virus o spyware y no puede acceder a la información en el disco duro del usuario que las instaló). </p>
                    <p className="fs-7 text-dark m-1">Una cookie consta de 2 partes: </p>
                    <p className="fs-7 text-dark m-1">- nombre </p>
                    <p className="fs-7 text-dark m-1">- el contenido o valor de la cookie </p>
                    <p className="fs-7 text-dark m-1">Además, la vida útil de una cookie está limitada desde el momento de la instalación. Técnicamente, solo el servidor web que envió la cookie puede acceder a ella nuevamente cuando un usuario regresa al sitio web/aplicación asociada con ese servidor web. </p>
                    <p className="fs-7 text-dark m-1">Las cookies no requieren el uso de información personal y, en la mayoría de los casos, no identifican a los usuarios de Internet. 
                    </p>
                    <p className="fs-7 text-dark m-1">Las cookies son el punto central del funcionamiento eficiente de Internet, ayudando a generar una experiencia de navegación amigable y adaptarse a las preferencias e intereses de cada usuario. </p>
                    <p className="fs-7 text-dark m-1">Rechazar o desactivar las cookies puede hacer que algunos sitios sean imposibles de usar. Rechazar o desactivar las cookies no significa que los usuarios dejarán de recibir publicidad en línea, solo que ya no se tendrán en cuenta sus preferencias e intereses, destacados por su comportamiento de navegación. </p>
                    <p className="fs-7 text-dark m-1">Son casi inevitables por su flexibilidad y porque la mayoría de los sitios y aplicaciones más visitados y grandes usan cookies. Desactivar las cookies no permitirá que el usuario acceda a los sitios y aplicaciones más populares y utilizados, incluidos Youtube, Gmail, Yahoo y otros. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Principales categorías de cookies </h5>
                    <p className="fs-7 text-dark m-1 fw-bold">Cookies de sesión </p>
                    <p className="fs-7 text-dark m-1">Estas se almacenan temporalmente en la carpeta de cookies del navegador web, para que se guarden hasta que el usuario salga del sitio web/aplicación respectiva o cierre la ventana del navegador. </p>
                    <p className="fs-7 text-dark m-1 fw-bold">Cookies persistentes </p>
                    <p className="fs-7 text-dark m-1">Estas se guardan en el disco duro de una computadora o dispositivo (y generalmente dependen del tiempo de vida predeterminado de la cookie). Las cookies persistentes incluyen aquellas colocadas por un sitio web/aplicación que no es el que el usuario accede en ese momento, conocidas como 'cookies de terceros', que pueden ser utilizadas de manera anónima. Su función es memorizar los intereses de un usuario para que se entregue la publicidad más relevante a los visitantes. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Ventajas de las cookies </h5>
                    <p className="fs-7 text-dark m-1">Una cookie contiene información que vincula un navegador web (usuario/visitante) a un servidor web específico (Sitio web/Aplicación). Si un navegador accede a ese servidor web nuevamente, puede leer la información ya almacenada y reaccionar en consecuencia. </p>
                    <p className="fs-7 text-dark m-1">Las cookies proporcionan a los usuarios una experiencia de navegación agradable y respaldan los esfuerzos de muchos sitios web/aplicaciones para proporcionar servicios cómodos y fácilmente accesibles a los usuarios: por ejemplo, preferencias con respecto a la confidencialidad en línea o publicidad relevante. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Periodo de vigencia de las cookies </h5>
                    <p className="fs-7 text-dark m-1">Las cookies son administradas por servidores web. El período de validez de una cookie es limitado y puede variar significativamente, según el propósito para el cual se coloca.</p>
                    <p className="fs-7 text-dark m-1">Algunas cookies se utilizan solo para una sesión (cookies de sesión) y ya no se conservan una vez que el usuario ha abandonado el sitio web/aplicación, y algunas cookies se conservan y reutilizan cada vez que el usuario regresa a ese sitio web/aplicación hasta la fecha de vencimiento, lo que puede ser unos minutos, horas, días o incluso años (cookies permanentes o fijas).  </p>
                    <p className="fs-7 text-dark m-1">El usuario puede eliminar las cookies en cualquier momento con la configuración del navegador. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Cookies colocadas por terceros </h5>
                    <p className="fs-7 text-dark m-1">Algunas secciones de contenido en algunos sitios web/aplicaciones pueden ser proporcionadas a través de terceros/proveedores (por ejemplo, un cuadro de noticias, un video o un anuncio). Estos terceros también pueden colocar cookies a través de UPBIRK y se llaman "cookies de terceros" porque no son colocadas por el propietario del sitio web/aplicación  respectiva. Los proveedores de terceros también deben cumplir con la ley vigente y las políticas de privacidad del Propietario. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Uso de cookies por parte del Sitio y la Aplicación </h5>
                    <p className="fs-7 text-dark m-1">Una visita al Sitio/Aplicación puede colocar cookies para los siguientes fines: </p>
                    <p className="fs-7 text-dark m-1">- Cookie necesaria para el funcionamiento del Sitio/Aplicación</p>
                    <p className="fs-7 text-dark m-1">- Cookies relacionadas con análisis </p>
                    <p className="fs-7 text-dark m-1">- Cookies para publicidad </p>
                    <p className="fs-7 text-dark m-1">- Cookies relacionadas con la funcionalidad </p>
                    <p className="fs-7 text-dark m-1">Por favor, consulte más información sobre cada tipo de cookie utilizando la configuración de cookies. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Información procesada mediante cookies </h5>
                    <p className="fs-7 text-dark m-1">Las cookies almacenan información en un pequeño archivo de texto que permite que un sitio web/aplicación reconozca un navegador. El servidor web reconocerá el navegador hasta que la cookie expire o sea eliminada.</p>
                    <p className="fs-7 text-dark m-1">La cookie almacena información importante que mejora la experiencia de navegación en Internet, dependiendo del tipo de cookie implementada en UPBIRK, pero también aquellas que identifican errores de uso.</p>
                  </div>
                  <div className="pb-5">
                    <h5>Recomendaciones para el uso de cookies</h5>
                    <p className="fs-7 text-dark m-1">Las cookies están en todas partes y no pueden evitarse si el visitante desea disfrutar del acceso a los mejores y más grandes sitios de Internet, locales o internacionales.</p>
                    <p className="fs-7 text-dark m-1">Con una comprensión clara de cómo funcionan y los beneficios que aportan, los usuarios pueden tomar las medidas de seguridad necesarias para navegar por Internet con confianza. Así, pueden personalizar la configuración de su navegador para las cookies en cualquier momento, reflejando un nivel cómodo desde el punto de vista de la seguridad de los datos y el uso de cookies.</p>
                    <p className="fs-7 text-dark m-1">Si al usuario no le molestan las cookies y es la única persona que utiliza la computadora, puede establecer plazos largos para almacenar el historial de navegación y los datos de acceso personales. Sin embargo, si el usuario comparte el acceso a la computadora, puede considerar configurar el navegador para eliminar datos de navegación individuales cada vez que cierre el navegador. Esta es una forma de acceder a sitios/aplicaciones que colocan cookies y eliminar cualquier información del visitante al final de la sesión de navegación. </p>
                    <p className="fs-7 text-dark m-1">Muchas aplicaciones de detección y prevención de spyware incluyen la detección de ataques a sitios. Esto evita que el navegador acceda a sitios/aplicaciones que puedan aprovechar las vulnerabilidades del navegador o descargar software peligroso. Como resultado, los usuarios deben asegurarse de tener su navegador actualizado. Muchos de los ataques basados en cookies se realizan aprovechando las debilidades de las versiones antiguas de los navegadores.</p>
                    <p className="fs-7 text-dark m-1 fw-bold">Cómo detener las cookies </p>
                    <p className="fs-7 text-dark m-1">Desactivar y rechazar recibir ciertas cookies puede hacer que algunos sitios sean inutilizables, utilizables solo parcialmente o difíciles de visitar y usar, como es el caso del Sitio y la Aplicación. Además, rechazar aceptar cookies no significa que los usuarios dejen de recibir/ver publicidad en línea. </p>
                    <p className="fs-7 text-dark m-1">Se puede configurar en el navegador para que ya no se acepten estas cookies o el navegador puede aceptar solo ciertas categorías de cookies para las que se requiere el consentimiento del usuario (por lo que no se aplica a la categoría de cookies necesarias para el funcionamiento del Sitio/Aplicación). Todos los navegadores modernos ofrecen la posibilidad de cambiar la configuración de las cookies. Utilice la configuración de cookies a este respecto. </p>
                    <p className="fs-7 text-dark m-1">Así, el usuario puede modificar en cualquier momento las opciones respecto a las cookies para las que se requiere su consentimiento, deslizando/no deslizando la casilla correspondiente. Estas configuraciones se pueden encontrar en el pop-up disponible permanentemente en la parte inferior de la página, donde puede elegir entre: elegir todas las cookies Y cambiar la configuración manual de cookies. Tales configuraciones se pueden encontrar en la parte inferior de la página, donde cada usuario puede elegir entre aceptar todas las cookies y cambiar manualmente la configuración de las cookies, eligiendo las cookies según su preferencia.</p>
                    <p className="fs-7 text-dark m-1">Por lo tanto, los usuarios pueden optar por expresar su consentimiento para el uso de una, varias o todas las cookies que no pertenecen a la categoría de las indispensables para el uso de UPBIRKo la transmisión de comunicaciones y cambiar su decisión en cualquier momento, sin afectar de ninguna manera la posibilidad de navegar por el Sitio y la Aplicación.</p>
                    <p className="fs-7 text-dark m-1">Para comprender estas configuraciones, los siguientes enlaces pueden ser útiles:</p>
                    <p className="fs-7 text-dark m-1">Configuración de cookies en Internet Explorer: <a href="https://support.microsoft.com/kb/196955">https://support.microsoft.com/kb/196955</a></p>
                    <p className="fs-7 text-dark m-1">Configuración de cookies en Firefox: <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies">support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies</a></p>
                    <p className="fs-7 text-dark m-1">Configuración de cookies en Chrome: <a href="https://support.google.com/chrome/bin/answer.py?hl=ro&answer=95647">support.google.com/chrome/bin/answer.py?hl=ro&answer=95647</a></p>
                    <p className="fs-7 text-dark m-1">Configuración de cookies en Safari: <a href="https://support.apple.com/kb/PH5042">support.apple.com/kb/PH5042</a></p>
                    <p className="fs-7 text-dark m-1">Configuración de cookies de terceros: <a href="https://www.youronlinechoices.com/ro/">www.youronlinechoices.com/ro/</a></p>
                    <p className="fs-7 text-dark m-1">Guía de cookies de Microsoft: <a href="https://www.microsoft.com/info/cookies.mspx">www.microsoft.com/info/cookies.mspx</a></p>
                    <p className="fs-7 text-dark m-1">Todo sobre las cookies: <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a></p>
                    <p className="fs-7 text-dark m-1">Información sobre confidencialidad para publicidad en línea: <a href="https://www.youronlinechoices.com/ro/">www.youronlinechoices.com/ro/</a></p>
                    <p className="fs-7 text-dark m-1">Detalles sobre problemas de confidencialidad: <a href="https://www.youronlinechoices.eu/ro">www.youronlinechoices.eu/ro</a></p>
                    <p className="fs-7 text-dark m-1">Detalles sobre publicidad en Europa: <a href="https://www.iabeurope.eu/cookies-faq/internet-cookies—increasing-and-enhancing-yourinternet-surfing-experience/what-are-cookies-how-do-they-work—cookies-faq.aspx">www.iabeurope.eu/cookies-faq/internet-cookies—increasing-and-enhancing-yourinternet-surfing-experience/what-are-cookies-how-do-they-work—cookies-faq.aspx</a></p>
                  </div>
                  <div className="pb-5">
                    <h5>Disposiciones finales </h5>
                    <p className="fs-7 text-dark m-1">- Esta política se aplica a la Compañía y a los usuarios del Sitio y la Aplicación (ya sea que estén registrados o no). </p>
                    <p className="fs-7 text-dark m-1">- La Compañía se reserva el derecho de modificar/actualizar el contenido del Sitio y la Aplicación, incluidas las políticas a las que se hace referencia, a su exclusivo criterio, en cualquier momento y por cualquier motivo (incluidos, entre otros, los cambios legislativos o jurisprudenciales que puedan afectar las consecuencias publicadas en el Sitio o la Aplicación). La revisión de esta política en el futuro se indicará mediante la modificación de la fecha de "Última actualización" en la parte superior de la página. Después de la fecha en que se publique la política actualizada, el acceso al Sitio y/o la Aplicación representará la aceptación del usuario de estas condiciones actualizadas. </p>
                    <p className="fs-7 text-dark m-1">- Sin embargo, si hay cambios significativos que puedan afectar los derechos y libertades de los visitantes, se les informará sobre esos cambios mediante indicaciones fácilmente visibles publicadas en el Sitio/Aplicación (pop-ups) o mediante el envío de correos electrónicos a las direcciones proporcionadas si corresponde. Tales cambios significativos tendrán efectos para los usuarios dentro de los 15 días a partir de la fecha de publicación del pop-up en cuestión o del envío del correo electrónico por parte de UPBIRK (cómo se proporcionará la información lo decide la Compañía, caso por caso). </p>
                    <p className="fs-7 text-dark m-1">- Sin embargo, independientemente de la magnitud del cambio, la responsabilidad de verificar el contenido del Sitio y la Aplicación (incluidos los Términos y Condiciones, así como las políticas mostradas), para estar al día con las últimas versiones, será enteramente responsabilidad del usuario. Por tanto, EL ESTUDIO DE LOS TÉRMINOS Y CONDICIONES, PERO TAMBIÉN DE LA POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS Y DE LA POLÍTICA DE COOKIES, DEBE REALIZARSE POR PARTE DE LOS USUARIOS CADA VEZ QUE ACCEDEN A ESTE SITIO O APLICACIÓN, Y ANTES DE REALIZAR REGISTRO O PROPORCIONAR DATOS.</p>
                    <p className="fs-7 text-dark m-1">- Este documento es parte del conjunto de políticas de seguridad de la Compañía. Otras políticas pueden aplicarse a los temas abordados en este documento y se revisarán según las necesidades específicas y pueden ser solicitadas por el usuario.</p>
                  </div>
                </div>
                {/*  .nl-entry  */}
              </div>
              {/*  .nl-entry-wrap  */}
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Terms Section End  */}

      {/*  CTA Section Start  */}
      <NioSection>
        <NioSection.Content>
          <CallToAction />
        </NioSection.Content>
      </NioSection>
      {/*  CTA Section End  */}

      {/*  CTA Section End  */}



    </AppLayout>
  )
}

export default index;