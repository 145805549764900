import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

// layout
import AppLayout from '../../layouts/AppLayout/AppLayout';

// components 
import { NioSection, NioButton, NioMedia, NioCard } from '../../components';

// section content 
import CallToAction from '../../components/PageComponents/SectionComponents/CallToAction/CallToAction';


function index() {
  return (
    <AppLayout title="Características de Upbirk: Consolida tu Proyecto en un Único Espacio " layoutClass="3">

      <Helmet>
          <title>Features: Consolida tu Proyecto en un Único Espacio | Upbirk</title>
          <meta name='description' content='Dispondrás de las herramientas necesarias para impulsar un proyecto de innovación, permitiéndote desarrollar cada una de ellas de forma eficaz.'/>
          <link rel='canonical' href='/Features'/>
      </Helmet>

      {/*  Discover Section Start  */}
      <section className="nl-section nl-banner nl-banner-analytics overflow-hidden bg-primary-gradient-soft">
        <div className="nl-banner-wrap pb-0 pb-md-8 overflow-hidden">
          <Container>
            <Row className="justify-content-center m-auto">
              <Col lg="10" xl="8">
                <div className="nl-banner-content text-center">
                  <h3 className="display-4 m-0 pb-5">Consolida la información de tu Proyecto en un <span style={{color:"#3680BC"}}>Único Espacio</span></h3>
                  <p className="fs-5 ">Dispondrás de las herramientas necesarias para impulsar un proyecto de innovación, permitiéndote desarrollar cada una de ellas de forma individual y/o integrarte en iniciativas llevados a cabo por los distintos stakeholders con las cuales estás conectado.</p>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="12" lg="10" xl="12">
                <div className="pt-6 mt-md-2 pb-7 pb-md-0 w-100">
                  <div className="position-relative nl-banner-img nl-banner-img-s1">
                    <img className="thumb-base rounded-3 border" src="images/thumb/Frame 1380.svg" alt="thumb-content"/>                   
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <section className="pb-8">
        <div className="">
          <Container>
            <Row className="justify-content-center m-auto mt-0">
              <Col lg="10" xl="8">
                <div className="nl-banner-content text-center">
                  <h3 className="display-5 m-0 pb-5">Como <span style={{color:"#3680BC"}}>Funciona</span></h3>
                  <p className="fs-5">Evita el tener que enviar la misma información a los distintos stakeholders involucrados en el desarrollo de un proyecto de innovación de una manera eficiente, centralizando en una sola plataforma la manera en la que esta se crea y distribuye.</p>
                </div>
              </Col>
            </Row>          
          </Container>
        </div>
      </section>
      {/*  Discover Section End  */}
      <NioSection className="nl-process-section pt-0 pb-0">
        <NioSection.Content>
          <ul className="nl-schedule d-flex flex-column gap-5 gap-md-7  nl-schedule-s1">
            <li className="nl-schedule-item p-0">
              <div className="nl-schedule-item-inner">
                <div className="nl-schedule-symbol">
                  <NioMedia size="lg" rounded="circle" variant="blue-soft" className="fw-bold" style={{color:"#3680BC"}}>
                    1
                  </NioMedia>
                </div>
                <div className="nl-schedule-content">
                  <Row className="flex-row-reverse justify-content-between">
                    <Col lg="7" >
                      <div className="nl-feature-overview-img text-lg-end mb-4 mb-sm-0 mb-sm-n3 mb-lg-n0">                        
                        <img src="images/business-tracker/Frame 1377 2.png" className="" alt="cover-bg" />
                      </div>
                    </Col>                 
                    <Col lg="5" >                    
                      <div className="nl-section-head" >
                        <h3 className="m-0 pb-5" style={{fontSize:"40px"}}>Paso 1: <span style={{color:"#3680BC"}}>Desarrolla</span> tu Proyecto</h3>
                        <p className=""style={{textAlign:"justify"}}>Ingresa la información de tu proyecto en la plataforma y comienza a utilizar los frameworks disponibles para estructurarlo de la manera que tú determines, una vez completado confirma que deseas compartir esta información con la institución que la está solicitando.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </li>
            <li className="nl-schedule-item p-0">
              <div className="nl-schedule-item-inner">
                <div className="nl-schedule-symbol">
                  <NioMedia size="lg" rounded="circle" variant="blue-soft" className="fw-bold" style={{color:"#3680BC"}}>
                    2
                  </NioMedia>
                </div>
                <div className="nl-schedule-content">
                  <Row className="flex-row-reverse justify-content-between">
                    <Col lg="7" >
                      <div className="nl-feature-overview-img text-lg-end mb-4 mb-sm-0 mb-sm-n3 mb-lg-n0 ">
                        <img src="images/business-tracker/Frame 1376 1.png" className="" alt="cover-bg" />
                      </div>
                    </Col>
                    <Col lg="5" >
                      <div className="nl-section-head ">
                        <h3 className="m-0 pb-5" style={{fontSize:"40px"}}>Paso 2: <span style={{color:"#3680BC"}}>Vincúlate</span> con organizaciones</h3>
                        <p className="" style={{textAlign:"justify"}}>Acepta las invitaciones de los stakeholders que están involucrados en el desarrollo de tu proyecto e inicia una interacción activa, donde ellos podrán solicitarte detalles o información específica necesaria para el avance conjunto.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </li>
            <li className="nl-schedule-item p-0">
              <div className="nl-schedule-item-inner p-0">
                <div className="nl-schedule-symbol">
                  <NioMedia size="lg" rounded="circle" variant="blue-soft" className="fw-bold" style={{color:"#3680BC"}}>
                    3
                  </NioMedia>
                </div>
                <div className="nl-schedule-content">
                  <Row className="flex-row-reverse justify-content-between">
                    <Col lg="7" >
                      <div className="nl-feature-overview-img text-lg-end mb-4 mb-sm-0 mb-sm-n3 mb-lg-n0">
                        <img src="images/business-tracker/Frame 1372.png" className="" alt="cover-bg" />
                      </div>
                    </Col>
                    <Col lg="5" >
                      <div className="nl-section-head">
                        <h3 className="m-0 pb-5" style={{fontSize:"40px"}}>Paso 3: <span style={{color:"#3680BC"}}>Selecciona</span> lo que deseas compartir</h3>
                        <p className="" style={{textAlign:"justify"}}>Decide qué elementos de tu proyecto quieres hacer visibles para estos stakeholders y mantén un control en la forma en que esta información relevante es compartida así como la manera en que quieres que esta información sea distribución.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </li>
          </ul>
        </NioSection.Content>
      </NioSection>

      {/*  Values Section Start  */}
      <section className="pt-8">
        <div className="">
          <Container>
            <Row className="justify-content-center m-auto mt-0">
              <Col lg="10" xl="9">
                <div className="nl-banner-content text-center">
                  <h3 className="display-5 m-0 pb-5">Que puedes <span style={{color:"#3680BC"}}>Compartir</span></h3>
                  <p className="fs-5">Toda la data generada por tu proyecto de innovación puede ser compartida en Upbirk, donde cualquier actualización de estos es notificada por la plataforma a los stakeholders a los cuales te encuentras vinculados, evitando así el que tengas que estar informando de estos cambios a cada uno de ellos de manera separada.</p>
                </div>
              </Col>
            </Row>          
          </Container>
        </div>
      </section>
      <NioSection className="pt-8 pb-8">
        <NioSection.Content>
          <Row className="gy-3 gy-lg-5">
            <Col md="6" lg="4" >
              <NioCard className="h-100 border-4">
                <NioCard.Body className="d-flex flex-column  gap-5">
                  <NioMedia size="lg" rounded="circle" variant="blue-soft" icon="file-text-fill" style={{color:"#3680BC"}}/>
                  <div >
                    <h5>Características del Proyecto: </h5>
                    <p className="fs-7 mb-0" style={{textAlign:"justify"}}>Incluye información actualizada y relevante sobre el desarrollo del proyecto.</p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="h-100 border-4">
                <NioCard.Body className="d-flex flex-column  gap-5">
                  <NioMedia size="lg" rounded="circle" variant="blue-soft" icon="pie-fill" style={{color:"#3680BC"}}/>
                  <div>
                    <h5>Frameworks vinculados a Rutas de Innovación:</h5>
                    <p className="fs-7 mb-0" style={{textAlign:"justify"}}>Puedes compartir los frameworks que selecciones con las instituciones correspondientes.</p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="h-100 border-4">
                <NioCard.Body className="d-flex flex-column  gap-5">
                  <NioMedia size="lg" rounded="circle" variant="blue-soft" icon="tree-structure-fill" style={{color:"#3680BC"}}/>
                  <div>
                    <h5>Reportes mensuales solicitados:</h5>
                    <p className="fs-7 mb-0" style={{textAlign:"justify"}}>puedes compartir estos distintos reportes, con distintas instituciones.</p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Values Section End  */}
      <NioSection className=" pb-8 pt-0 overflow-hidden ">
        <Container>
          <Row className="justify-content-center ">
            <Col lg="10" xl="9">
              <div className="nl-banner-content text-center">
                <h3 className="display-5 m-0 pb-5">La Información <span style={{color:"#3680BC"}}>Siempre Sera Tuya</span></h3>
                <p className="fs-5 ">Todo lo que completes en nuestra plataforma será de tu propiedad y tendrás el control total sobre su distribución, donde tú decides si deseas terminar de compartir datos o desvincularte de alguna de las entidades con las cuales estás conectada.</p>
                <NioButton href="/Politica" className="btn mt-2" label="Privacidad de Datos y Políticas del sistema"  style={{backgroundColor:"#3680BC"}}/>
              </div>
            </Col>
          </Row>
        </Container>
      </NioSection>
      {/*  CTA Section Start  */}
      <NioSection className="pt-0 pb-8">
        <NioSection.Content>
          <CallToAction />
        </NioSection.Content>
      </NioSection>
      {/*  CTA Section End  */}



    </AppLayout >
  )
}

export default index;