import React from 'react';
import { Col, Row } from 'react-bootstrap';

// layout 
import AppLayout from '../../layouts/AppLayout/AppLayout';

// section content 
import { NioSection } from '../../components';
import CallToAction from '../../components/PageComponents/SectionComponents/CallToAction/CallToAction';

function index() {
  return (
    <AppLayout layoutClass="3" title="Política de Privacidad y Protección de Datos ">

      {/*  Terms Section Start  */}
      <NioSection className="pt-120 pt-lg-180">
        <div className="nl-block-head md">
          <div className="nl-section-head pb-0">
            <h2 className='text-center'>Politica de Privacidad y Protección de Datos</h2>
          </div>
        </div>
        <NioSection.Content>
          <Row className="pt-3 justify-content-center">
            <Col lg="9">
              <div className="nl-entry-wrap pt-3 ps-lg-5">
                <div className="nl-entry" style={{ textAlign: "justify" }}>
                  <div className="pb-5">
                    <h5>Política de Privacidad y Protección de Datos </h5>
                    <p className="fs-7 text-dark">Esta política detalla la práctica de INNOVACIONES Y DESARROLLOS TECNOLÓGICOS UPBIRK SPA con respecto a los datos personales utilizados por el dominio upbirk.com y la aplicación UPBIRK (en adelante, UPBIRK la Aplicación, respectivamente el Sitio web o el Sitio) y tiene como objetivo informar a los usuarios sobre este tema. Al utilizar UPBIRK, los usuarios reconocen que han tomado conocimiento y aceptan esta Política de Privacidad y Protección de Datos, así como la Política de Cookies y los Términos y Condiciones publicados en UPBIRK. </p>
                    <p className="fs-7 text-dark">
                      Última actualización: 25 de enero de 2024
                    </p>
                  </div>
                  <div className="pb-5">
                    <h5>
                      Identificación del responsable del tratamiento de datos
                    </h5>
                    <p className="fs-7 text-dark">
                      <b>Nombre:</b> INNOVACIONES Y DESARROLLOS TECNOLÓGICOS UPBIRK SPA (en adelante, la Empresa, el Propietario o UPBIRK SPA)
                    </p>
                    <p className="fs-7 text-dark">
                      <b>Dirección:</b> 1 NORTE 461 OF 703 VERGARA VINA DEL MAR, CHILE.
                    </p>
                    <p className="fs-7 text-dark">
                      <b>Rut:</b> 77.381.573-9
                    </p>
                    <p className="fs-7 text-dark">
                      <b>Correo electrónico:</b> office@upbirk.com
                    </p>
                  </div>
                  <div className="pb-5">
                    <h5>Detalles de contacto en el ámbito de la protección de datos personales </h5>
                    <p className="fs-7 text-dark">La Empresa recopila información de los usuarios de las siguientes maneras: directamente del usuario, a través de informes de tráfico registrados por los servidores que alojan UPBIRK, mediante cookies, así como de fuentes públicas o privadas de terceros. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Información proporcionada directamente por el usuario </h5>
                    <p className="fs-7 text-dark m-1">- La Empresa puede recopilar la siguiente información de sus usuarios: nombre, apellido, correo electrónico, nombres de usuario y fotos de perfil en redes sociales, otra información de contacto, educación y empleos anteriores, detalles biográficos, cuenta bancaria, detalles de tarjeta de crédito, dirección de correo electrónico de PayPal, número de identificación fiscal, dirección, cargo y empleador. Los detalles y contextos de la recopilación de esta información se describen a continuación. </p>
                    <p className="fs-7 text-dark m-1">- Cuando el usuario se registra en la aplicación UPBIRK, completa los campos en el formulario de registro indicando nombre, apellido, correo electrónico y contraseña (todos son datos personales); alternativamente, pueden registrarse utilizando un inicio de sesión social (LinkedIn, Google, Twitter, Facebook), indicando nombre, apellido, correo electrónico, nombre de usuario en la red social y foto de perfil. Esta es la única información obligatoria requerida por la Empresa para poder crear una cuenta válida para cada usuario. La misma información se utilizará más tarde, después de que se haya creado la cuenta, para el proceso de inicio de sesión. En cualquier caso, la contraseña no es visible para la Empresa. No se extrae ningún otro dato de las redes sociales utilizadas con fines de inicio de sesión. </p>
                    <p className="fs-7 text-dark m-1">- Cuando el usuario completa los campos en cualquiera de los formularios de Contacto o Solicitud de información, indica nombre, apellido y correo electrónico (todos son datos personales). Esta es la única información obligatoria requerida por la Empresa para poder responder al mensaje enviado por cada usuario. En este caso, no se recopilan contraseñas. Otros datos personales podrían ser procesados por la Empresa si están incluidos en la información/solicitud enviada por el usuario mediante cualquiera de los formularios de Contacto/Solicitud de información. La Empresa puede o no solicitar los datos respectivos, pero en la medida en que sean absolutamente necesarios para poder responder a los transmitidos por el usuario a través del formulario, el procesamiento de datos personales se realizará a solicitud del usuario de UPBIRK. </p>
                    <p className="fs-7 text-dark m-1">- Cuando el usuario completa los campos en la sección de Perfil en la aplicación UPBIRK, indica su nombre y apellido (todos son datos personales). Esta es la única información obligatoria requerida por la Empresa para tener un usuario válido. Estos datos son públicos, lo que significa que son visibles para cualquier usuario que cree una cuenta en UPBIRK. </p>
                    <p className="fs-7 text-dark m-1">- Los usuarios que tienen cuentas pueden agregar en su Perfil información adicional sobre su negocio, datos de contacto, educación y empleos anteriores, perfiles sociales con enlaces correspondientes, incluso un breve discurso y biografía, foto e incluso indicar otros enlaces relevantes. Esta información no es obligatoria; el usuario es libre de decidir si la indica o no. La validez de su perfil y el acceso a UPBIRK no se verán restringidos de ninguna manera si no se completan los datos mencionados anteriormente. Sin embargo, los usuarios deben ser conscientes de que UPBIRK puede ver toda esa información en su versión más reciente, al igual que otras organizaciones y usuarios con cuentas en la plataforma. </p>
                    <p className="fs-7 text-dark m-1">- Cuando el usuario completa los campos en la sección de Pago o en el servicio de facturación de terceros (Stripe, Chargebee), indica nombre y apellido, número de cuenta bancaria, PayPal, dirección, número de identificación fiscal, tarjeta de crédito (que puede ser información personal, a menos que el usuario esté creado en el momento). Esta es la única información obligatoria requerida por la Empresa para recibir un pago válido y emitir una factura legal. </p>
                    <p className="fs-7 text-dark m-1">- Cuando el usuario da su consentimiento para recibir boletines informativos o información comercial, indica su nombre, apellido, organización, cargo y dirección de correo electrónico (datos personales). Esta es la única información obligatoria requerida por la Empresa para enviar información sobre sus productos y servicios, noticias, artículos, eventos y otros materiales orientados al marketing. </p>
                    <p className="fs-7 text-dark m-1">- La Empresa confirma que ninguno de los datos personales indicados anteriormente se utilizará con fines distintos a los expresamente indicados, especialmente porque no se procesarán con fines de marketing sin observar las disposiciones legales. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Información obtenida de los informes de tráfico registrados por el servidor </h5>
                    <p className="fs-7 text-dark m-1">- Cuando se accede a un sitio web/aplicación, los usuarios revelan automáticamente cierta información, como la dirección IP, la hora de la visita y el lugar desde donde se accedió al sitio web/aplicación. La Empresa, al igual que otros operadores, registra esta información con el propósito de seguridad y protección de todos los usuarios, así como para fines de mejora de los Sitios web/Aplicaciones. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Información obtenida a través de cookies </h5>
                    <p className="fs-7 text-dark m-1">- Todos los detalles sobre cómo se procesan los datos en este contexto se indican en la Política de Cookies disponible en UPBIRK. Los detalles de contacto que el usuario puede utilizar para transmitir cualquier solicitud, notificación o reclamo con respecto a esta Política de Privacidad y Protección de Datos, así como los Términos y Condiciones y la Política de Cookies, u otra información publicada en UPBIRK, políti	cas u operaciones realizadas por la Empresa, se indican anteriormente.
                      El plazo para que la Empresa envíe una respuesta es de no más de 30 días a partir de la recepción de la solicitud. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Información obtenida de terceros  </h5>
                    <p className="fs-7 text-dark m-1">- La Empresa puede utilizar sitios web públicos, aplicaciones, redes sociales, APIs, fuentes técnicas u otros servicios de terceros para recopilar datos sobre usuarios y empresas. Estos datos incluyen, pero no se limitan a: detalles demográficos (género, edad, ubicación, etc.), experiencia laboral (empleos, voluntariado, habilidades, artículos profesionales o contribuciones), antecedentes educativos (instituciones, títulos, investigación publicada, etc.), datos de la empresa (información empresarial, empleos, clientes, financiación, ingresos, productos y servicios, etc.), o cualquier otra información sobre una persona/empresa que pueda ser relevante para otros usuarios que deseen encontrar oportunidades de inversión, ventas o contratación. </p>
                    <p className="fs-7 text-dark m-1">- Estos datos son procesados además por la Empresa utilizando sus sistemas internos, para intereses comerciales legítimos. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Titular de los datos </h5>
                    <p className="fs-7 text-dark m-1">- Dado que la Empresa procesa los datos personales de los usuarios de UPBIRK (antes y después de registrar una cuenta), estos tienen el estatus de titular de los datos y declaran que tienen más de 18 años. Si la información/solicitudes transmitidas por los usuarios también conciernen a datos personales relacionados con otras personas (quienes adquieren así el estatus de titular de los datos), la Empresa procesará sus datos estrictamente para poder responder a esa información/solicitud. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Datos personales procesados </h5>
                    <p className="fs-7 text-dark m-1">- Cualquier información sobre una persona natural identificada o identificable, respectivamente el titular de los datos puede considerarse datos personales. Considerando los propósitos de procesamiento indicados en este documento, la Empresa intenta reducir tanto como sea posible los datos personales procesados y excluye cualquier dato personal que no sea relevante para intereses comerciales legítimos. </p>
                    <p className="fs-7 text-dark m-1">- Los usuarios son responsables de cualquier dato personal de terceros obtenido, publicado o compartido a través de UPBIRKUPBIRK y confirman que tienen el consentimiento de ese tercero para proporcionar los datos.  </p>
                    <p className="fs-7 text-dark m-1">- Por lo tanto, de acuerdo con la Política de Cookies, el titular de los datos podrá elegir los tipos de cookies (aplicables cuando su uso no se realiza automáticamente para el funcionamiento de UPBIRK) para garantizar una experiencia de navegación más completa y mejor al navegar por UPBIRK. </p>
                    <p className="fs-7 text-dark m-1">
                      <lu >
                        <li className='m-1'>
                          Para configurar la cuenta utilizando el formulario de registro y para acceder a la cuenta utilizando el formulario de inicio de sesión, la Empresa procesa los siguientes datos: nombre y apellido del usuario, dirección de correo electrónico, URL del perfil social, imagen de perfil, contraseña, IP, tipo/versión del navegador y tipo de dispositivo.
                        </li>
                        <li className='m-1'>
                          Para la transmisión de respuestas a las solicitudes/notificaciones enviadas por los usuarios utilizando los formularios de Contacto o Solicitud de información, la Empresa procesa los siguientes datos: nombre y apellido del usuario, dirección de correo electrónico y cualquier otro dato personal proporcionado por el usuario en su mensaje, si es necesario para responder, IP, tipo/versión del navegador, tipo de dispositivo.
                        </li>
                        <li className='m-1'>
                          Para administrar la plataforma UPBIRK y las cuentas creadas por los usuarios, así como para suspender y eliminar una cuenta si es necesario, la Empresa procesa los siguientes datos de su Perfil: nombre y apellido, dirección de correo electrónico, versión/tipo de navegador, IP y cualquier otro dato personal proporcionado por el usuario en su perfil.
                        </li>
                        <li className='m-1'>
                          Para procesar el pago de la suscripción, así como para resolver cualquier problema de pago que pueda aparecer, la Empresa requiere que el usuario complete los siguientes datos en la sección de Pago o aplicaciones de pago/facturación de terceros: nombre y apellido, dirección de correo electrónico, número de cuenta bancaria, número de tarjeta de crédito/débito/usuario de PayPal, dirección, número de identificación fiscal, IP, tipo/versión del navegador.
                        </li>
                        <li className='m-1'>
                          Para enviar boletines informativos, invitaciones, artículos, promociones y cualquier otro material orientado al marketing, la Empresa requiere que el usuario acepte recibir información comercial y ofrece la posibilidad de darse de baja al final de cada correo electrónico enviado al usuario: nombre y apellido, dirección de correo electrónico, IP, tipo/versión del navegador.
                        </li>
                        <li className='m-1'>
                          Para fines de inversión, oportunidades comerciales o de contratación, la Empresa puede recopilar información de terceros y sitios web sobre el uso que es relevante para estos contextos: nombre completo, género, edad, fecha de nacimiento, lugar de nacimiento, ubicación actual, idiomas hablados, direcciones de correo electrónico, números de teléfono, historial laboral (empresas, empleos, año, experiencia, habilidades, responsabilidades, proyectos, revisiones de desempeño, etc.), antecedentes educativos (títulos, instituciones, acreditaciones, investigación, publicaciones, revisiones, proyectos) y otros detalles que estén públicamente disponibles. La Empresa ofrece la posibilidad al usuario de solicitar la actualización o eliminación de su información personal poniéndose en contacto con la Empresa en office@upbirk.com.
                        </li>
                        <li className='m-1'>
                          Dependiendo de la configuración de las cookies, se pueden procesar otros datos (especialmente aquellos relacionados con las preferencias y el comportamiento del usuario en UPBIRK).
                        </li>
                      </lu>
                    </p>
                  </div>
                  <div className="pb-5">
                    <h5>Procesamiento de datos personales </h5>
                    <p className="fs-7 text-dark m-1">- Representa el procesamiento de datos personales, cualquier operación o conjunto de operaciones realizadas en datos personales o conjuntos de datos personales, con o sin el uso de medios automatizados. La Empresa accede, recopila, utiliza y realiza cualquier otra acción permitida por la ley aplicable sobre los datos personales proporcionados por los usuarios, dentro de los límites indicados anteriormente. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Propósito </h5>
                    <p className="fs-7 text-dark m-1">- Un usuario es una persona que accede a UPBIRK o cuyos datos personales se procesan para diferentes fines (respectivamente, usted). </p>
                    <p className="fs-7 text-dark m-1">- Los datos personales proporcionados directamente por el usuario se procesan para estos fines:  </p>
                    <p className="fs-7 text-dark m-1">
                      <lu >
                        <li className='m-1'>
                          Proporcionar respuestas, aclaraciones y soluciones a situaciones problemáticas, relacionadas con las solicitudes y notificaciones enviadas por el usuario a través de cualquiera de los formularios de contacto.
                        </li>
                        <li className='m-1'>
                          Crear y administrar cuentas de usuario, a través de los formularios de registro e inicio de sesión.
                        </li>
                        <li className='m-1'>
                          Administrar y desarrollar la plataforma UPBIRK, así como los perfiles allí (incluyendo su suspensión y eliminación), incluyendo alojamiento, gestión de base de datos de usuarios, protección contra spam, salvaguarda y gestión de copias de seguridad, alojamiento e infraestructura de backend, comentarios de contenido, registro de pagos y facturación.
                        </li>
                        <li className='m-1'>
                          Transmitir materiales orientados al marketing a suscriptores en los Boletines de UPBIRK.
                        </li>
                        <li className='m-1'>
                          Garantizar el cumplimiento de esta Política de Privacidad y Protección de Datos, los Términos y Condiciones y la Política de Cookies, así como las disposiciones legales aplicables para la protección de los derechos, propiedad o seguridad de UPBIRK.
                        </li>
                      </lu>
                    </p>
                    <p className="fs-7 text-dark">- Los datos personales proporcionados por los informes de tráfico registrados por el servidor se procesan para estos fines: </p>
                    <p className="fs-7 text-dark">
                      <lu >
                        <li className='m-1'>
                          Identificación de las secciones de interés de UPBIRK.
                        </li>
                        <li className='m-1'>
                          Administración más segura del sistema informático y UPBIRK.
                        </li>
                      </lu>
                    </p>
                    <p className="fs-7 text-dark">- Los datos personales proporcionados por el uso de cookies se procesan para estos fines: </p>
                    <p className="fs-7 text-dark">
                      <lu >
                        <li className='m-1'>
                        Funcionamiento y operación fluida de UPBIRK (cookie necesaria). 
                        </li>
                        <li className='m-1'>
                        Dependiendo de la configuración elegida por el usuario, se pueden utilizar datos personales adicionales para obtener información estadística que mejore los servicios ofrecidos, guardar preferencias, publicidad, análisis, targeting de comportamiento, gestión de etiquetas, visualización de contenido desde la plataforma externa, acceso a cuentas de terceros, pruebas de rendimiento y características de contenido (pruebas A/B), interacción con plataformas de recopilación de datos y otros terceros, características sociales, etc. 
                        </li>
                      </lu>
                    </p>
                    <p className="fs-7 text-dark">- Los datos personales recopilados a través de sitios web y servicios de terceros se procesan para estos fines:</p>
                    <p className="fs-7 text-dark">
                      <lu >
                        <li className='m-1'>
                        Conectar usuarios con fines de inversión (como un inversionista que contacta a un fundador de una startup para ofrecer una inversión en su startup; un fundador de una startup que conecta a un inversionista para solicitar una inversión en su startup; inversionistas que se conectan para hacer inversiones conjuntas en startups; u otros fines similares). 
                        </li>
                        <li className='m-1'>
                        Conectar usuarios con oportunidades comerciales (ampliamente consideradas como comunicación de ventas o marketing directo, como ofertas de nuevos productos y servicios, asociaciones u otras oportunidades comerciales).  
                        </li>
                        <li className='m-1'>
                        Conectar usuarios con fines de oportunidades laborales (como un reclutador que contacta a un candidato potencial para ofrecer un trabajo que podría interesar al candidato).  
                        </li>
                        <li className='m-1'>
                        La Empresa recopila y procesa un gran volumen de datos personales de terceros, específicamente con el propósito de ofrecer conexiones muy dirigidas y relevantes para los usuarios, y utiliza estos datos para evitar que los usuarios se acerquen a otros usuarios con ofertas o conexiones irrelevantes.  
                        </li>
                      </lu>
                    </p>
                    <p className="fs-7 text-dark">- Si la Empresa tiene la intención de procesar posteriormente los datos personales para un fin distinto de los indicados anteriormente, proporcionará al titular de los datos, antes de dicho procesamiento adicional, información relevante adicional sobre el propósito secundario, completando las formalidades necesarias de acuerdo con la ley. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Destinatarios del procesamiento </h5>
                    <p className="fs-7 text-dark m-1">Los datos personales serán proporcionados a: </p>
                    <p className="fs-7 text-dark m-1">- Los administradores y empleados/colaboradores de la Empresa que se ocupan de la administración y desarrollo de UPBIRKa y que están involucrados en las actividades con respecto a los perfiles de los usuarios o preguntas/notificaciones; </p>
                    <p className="fs-7 text-dark m-1">- Los proveedores de servicios de soporte contratados por la Empresa para cumplir con sus obligaciones contractuales o legales, tales como: </p>
                    <p className="fs-7 text-dark">
                      <lu >
                        <li className='m-1'>
                        Los diseñadores, los desarrolladores de software, los consultores de IT o empresariales y los capacitadores que pueden acceder a todos los datos registrados en los registros en línea de la Empresa, incluidos los de los usuarios indicados en el punto 4 anterior. 
                        </li>
                        <li className='m-1'>
                        Los abogados de la Empresa pueden acceder a todos los datos registrados en los registros de la Empresa, incluidos los de los usuarios, en caso de problemas legales que requieran su participación.  
                        </li>
                        <li className='m-1'>
                        Empresas de publicidad, relaciones públicas y comunicación para la actividad de marketing. Estas empresas pueden recopilar datos a través de cookies o mediante los formularios de registro para el evento o comentarios, y en la medida en que esto ocurra, la Empresa proporcionará esta información a los sujetos de datos con anticipación y obtendrá su consentimiento cuando sea necesario.  
                        </li>
                        <li className='m-1'>
                        Proveedores de servicios de IT y plataformas necesarias para la funcionalidad de UPBIRK, como (pero no limitado a) alojamiento, procesamiento de pagos, comportamiento de usuario, soporte al cliente, gestión de proyectos y productos, seguimiento de eventos, análisis, etc. 
                        </li>
                      </lu>
                    </p>
                    <p className="fs-7 text-dark m-1">- La lista de proveedores mencionados anteriormente no es exhaustiva, pero indica las principales empresas colaboradoras. Tendrán la capacidad de ser el controlador de datos independiente, el controlador de datos conjunto o el procesador de datos en relación con la Empresa, dependiendo de la situación fáctica y de las cláusulas del contrato. Sin embargo, independientemente de la calidad que tengan, están obligados a mantener la confidencialidad y seguridad de los datos personales del sujeto de datos (el usuario), adoptando medidas técnicas y organizativas apropiadas. A solicitud, la lista actualizada de proveedores y las cláusulas principales de esos contratos que pueden afectar los datos personales del usuario pueden ser comunicadas a ese usuario. 
                    Aunque no se consideran destinatarios de datos personales bajo las disposiciones legales, las autoridades públicas (incluida la autoridad fiscal y la autoridad de protección al consumidor) y los tribunales de justicia pueden procesar todos/algunos de los datos personales obtenidos mediante UPBIRK. </p>
                  </div>
                  <div className="pb-5">
                    <h5>El fundamento legal para el procesamiento de datos personales </h5>
                    <p className="fs-7 text-dark m-1">El procesamiento de los datos personales de nuestros usuarios cumple con al menos uno de estos artículos: </p>
                    <p className="fs-7 text-dark m-1">- Artículo 6 letra a del GDPR: el procesamiento se lleva a cabo en base al consentimiento del Usuario; situación aplicable cuando el procesamiento de los datos se realiza en el contexto de las cookies aceptadas por el usuario y que no son necesarias para el funcionamiento de UPBIRK; cuando el procesamiento de datos se realiza en el contexto de llenar los formularios indicados en cualquier formulario de contacto; para la creación de perfiles; así como cuando el procesamiento de datos para fines de marketing a la lista de suscriptores de Boletines o destinatarios de comunicación de marketing. </p>
                    <p className="fs-7 text-dark m-1">- Artículo 6 letra b del GDPR: el procesamiento se realiza para la ejecución de un contrato del que el interesado es parte o para tomar medidas a solicitud del interesado antes de celebrar un contrato; situación aplicable cuando el procesamiento se realiza en el contexto de los formularios de las secciones Registro, Iniciar sesión y Perfil. </p>
                    <p className="fs-7 text-dark m-1">- Artículo 6 letra c del GDPR: el procesamiento es necesario para el cumplimiento de una obligación legal a la que está sujeta la Empresa como controladora de datos; situación aplicable en el contexto del procesamiento de datos en relación con las autoridades competentes o proveedores de servicios legales, así como para el pago de la suscripción y facturación. </p>
                    <p className="fs-7 text-dark m-1">- Artículo 6 letra f del GDPR: el procesamiento es necesario para los fines de los intereses legítimos perseguidos por el controlador o por un tercero, excepto cuando dichos intereses sean anulados por los intereses o derechos fundamentales y libertades del interesado; situación aplicable en el contexto del procesamiento de datos para el funcionamiento y administración normal de UPBIRK. </p>
                    <p className="fs-7 text-dark m-1">En cualquier caso, la Empresa estará encantada de ayudar a aclarar la base legal específica que se aplica al procesamiento. .</p>
                  </div>
                  <div className="pb-5">
                    <h5>Tipo de procesamiento </h5>
                    <p className="fs-7 text-dark m-1">Las actividades de procesamiento de datos realizadas por la Empresa, principalmente se refieren a: </p>
                    <p className="fs-7 text-dark m-1">- La recopilación de los datos indicados por el usuario en los formularios de Contacto, Solicitud de información, Registro, Inicio de sesión, Perfil, Boletín y Formularios de Pago/Facturación. </p>
                    <p className="fs-7 text-dark m-1">- Uso de datos para proporcionar respuestas a los mensajes transmitidos por el usuario. </p>
                    <p className="fs-7 text-dark m-1">- Uso de datos para la conclusión y ejecución del contrato.</p>
                    <p className="fs-7 text-dark m-1">- Uso de datos para cada categoría de cookies elegida por el usuario.</p>
                    <p className="fs-7 text-dark m-1">- Uso de datos recopilados de terceros para intereses comerciales legítimos. </p>
                    <p className="fs-7 text-dark m-1">- Recopilación de otros datos no solicitados si son proporcionados por el usuario en una comunicación, solicitud o queja dirigida a la Empresa para que pueda responder y resolver la solicitud o remediar el incidente. </p>
                    <p className="fs-7 text-dark m-1">- Almacenamiento de datos personales de acuerdo con la ley y dentro de los límites necesarios para lograr el propósito, en la base de datos electrónica y segura mantenida por la Empresa. </p>
                    <p className="fs-7 text-dark m-1">- Permitir el acceso a datos personales a ciertos empleados y colaboradores externos que brindan servicios de soporte para la Empresa, cuya actividad implica el procesamiento de datos personales bajo la condición de asumir la obligación de confidencialidad y cumplimiento del GDPR. </p>
                    <p className="fs-7 text-dark m-1">- Permitir el acceso limitado a datos personales a otros usuarios con fines de oportunidades de inversión, negocios o trabajo. </p>
                    <p className="fs-7 text-dark m-1">- Permitir el acceso a datos personales a las autoridades competentes, en la medida en que la ley lo exige. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Retención de datos</h5>
                    <p className="fs-7 text-dark m-1">El periodo de retención de los datos personales procesados es el siguiente:</p>
                    <p className="fs-7 text-dark m-1">- Hasta la retirada del consentimiento o el ejercicio del derecho de supresión de datos (Derecho al olvido) del usuario, para el procesamiento de datos personales basado en el consentimiento del interesado dentro de los límites indicados por el art. 17 del Reglamento de la UE no. 679/2019. </p>
                    <p className="fs-7 text-dark m-1">- Hasta la eliminación del perfil de usuario a solicitud del usuario o por decisión de la Empresa (como resultado de una sanción por incumplimiento de los Términos y Condiciones o como resultado del vencimiento de la suscripción de UPBIRK de ese usuario): todos los datos indicados en el perfil, el inicio de sesión no estará disponibles después de esa fecha. </p>
                    <p className="fs-7 text-dark m-1">- Durante 3 años después de recibir el mensaje de cualquiera de los formularios de Contacto, con el fin de poder demostrar las medidas tomadas por la Empresa, considerando la duración del período general de limitación para el derecho de actuar ante los tribunales regulado por el Código Civil rumano. </p>
                    <p className="fs-7 text-dark m-1">- Durante 5 años después de actualizar el perfil de usuario con datos relevantes ya sea por la intervención del usuario o de información de terceros, ya que se considera un período aceptable para oportunidades de inversión, negocios o empleo.</p>
                    <p className="fs-7 text-dark m-1">- Un período más largo que el mencionado anteriormente, cuando la ley o la orden de una autoridad lo regule de esa manera o cuando exista un motivo bien justificado para esta acción (por ejemplo, para probar pagos y facturación, o para ejercer un derecho ante el tribunal en un litigio iniciado antes del vencimiento del período de almacenamiento indicado aquí).</p>
                    <p className="fs-7 text-dark m-1">Una vez expirados los períodos mencionados anteriormente, todos los datos serán eliminados de los registros de la Empresa. Por lo tanto, el derecho de acceso, el derecho de supresión, el derecho de rectificación y el derecho a la portabilidad de datos no podrán ser ejercidos después de la expiración del período de retención. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Derechos del interesado</h5>
                    <p className="fs-7 text-dark m-1 fw-bold">El derecho a ser informado.</p>
                    <p className="fs-7 text-dark m-1">- Por favor, revise al respecto la presente política, la Política de cookies y los Términos y condiciones.</p>
                    <p className="fs-7 text-dark m-1">- La Empresa se reserva el derecho de modificar/actualizar el contenido de UPBIRKa, incluidas las políticas a las que se hace referencia, a su sola discreción, en cualquier momento y por cualquier motivo (incluidos, entre otros, los cambios legislativos o jurisprudenciales que puedan afectar las consecuencias de los publicados en UPBIRK). La revisión de esta política en el futuro será señalada modificando la fecha de "Última actualización" en la parte superior de este documento. Después de la fecha en que se publique la política actualizada, el acceso a UPBIRK representará la aceptación por parte del usuario de estas condiciones actualizadas.</p>
                    <p className="fs-7 text-dark m-1">- Sin embargo, si hubiera cambios significativos que pudieran afectar los derechos y libertades de los usuarios o si fuera obligatorio obtener su consentimiento, se informará sobre estos cambios mediante indicaciones fácilmente visibles publicadas en UPBIRK (pops-up) o mediante el envío de correos electrónicos a las direcciones proporcionadas (si corresponde). Tales cambios significativos tendrán efectos sobre los usuarios dentro de los 15 días a partir del momento de publicación del pop-up en cuestión o del envío del correo electrónico (cómo se realizará la información será decidido por la Empresa, caso por caso).</p>
                    <p className="fs-7 text-dark m-1">- Independientemente de la magnitud del cambio, la responsabilidad de verificar el contenido de UPBIRK (incluidos los Términos y Condiciones, así como las políticas mostradas), para estar al día con las últimas versiones, será enteramente responsabilidad del usuario. Por lo tanto, UN ESTUDIO DE ESTA POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS, DE LOS TÉRMINOS Y CONDICIONES Y LA POLÍTICA DE COOKIES, DEBERÁ SER REALIZADO POR LOS USUARIOS CADA VEZ QUE ACCEDAN A UPBIRK, Y ANTES DE REALIZAR CUALQUIER REGISTRO O PROPORCIONAR CUALQUIER DATO, YA QUE LOS CAMBIOS PUEDEN APARECER.</p>
                    <p className="fs-7 text-dark m-1">- NA solicitud, el interesado será informado sobre la esencia de los contratos concluidos con los destinatarios de datos personales cuando sea posible, y también sobre la fuente de los datos.  </p>
                    <p className="fs-7 text-dark m-1 fw-bold">El derecho de acceso a los datos personales procesados </p>
                    <p className="fs-7 text-dark m-1">- Si el interesado desea recibir información sobre el procesamiento de datos realizado por la Empresa, puede enviar una solicitud a la Empresa, y se proporcionará una respuesta dentro de los 30 días siguientes a la recepción. </p>
                    <p className="fs-7 text-dark m-1 fw-bold">El derecho de rectificación de datos</p>
                    <p className="fs-7 text-dark m-1">- Si el interesado desea rectificar/modificar los datos personales inexactos/incompletos que le conciernen y que fueron proporcionados a la Empresa, puede enviar una solicitud a la Empresa, y se proporcionará una respuesta dentro de los 30 días siguientes a la recepción.</p>
                    <p className="fs-7 text-dark m-1">- Algunos elementos de los datos personales pueden ser actualizados por el usuario iniciando sesión y accediendo a su perfil en UPBIRK </p>
                    <p className="fs-7 text-dark m-1 fw-bold">El derecho de supresión de datos (el derecho al olvido)</p>
                    <p className="fs-7 text-dark m-1">El interesado tendrá derecho a obtener el borrado de los datos personales que le conciernan:</p>
                    <p className="fs-7 text-dark m-1">- Al vencimiento del período de procesamiento.</p>
                    <p className="fs-7 text-dark m-1">- Si los datos personales ya no son necesarios para los que se recogieron o trataron de otra manera.</p>
                    <p className="fs-7 text-dark m-1">- Si el interesado retira su consentimiento en el que se basa el tratamiento y no existe otro fundamento jurídico para el tratamiento.</p>
                    <p className="fs-7 text-dark m-1">- Si el interesado se opone al tratamiento y no existen motivos legítimos predominantes para el tratamiento. </p>
                    <p className="fs-7 text-dark m-1">- Si el tratamiento es ilegal, los datos personales son tratados ilícitamente.</p>
                    <p className="fs-7 text-dark m-1">- Los datos personales deben ser borrados para el cumplimiento de una obligación legal.</p>
                    <p className="fs-7 text-dark m-1">Los casos excepcionales se establecen en el art. 17 párrafo 3 del Reglamento Europeo n.º 679/2016.</p>
                    <p className="fs-7 text-dark m-1">Algunos datos forman parte de los registros de la Empresa, que mantiene en relación con sus obligaciones legales o su interés legítimo. Por tanto, no todos los datos pueden borrarse, según la ley. Sin embargo, cualquier negativa a borrar los datos deberá estar motivada por la Empresa y basarse en un fundamento jurídico claro. </p>
                    <p className="fs-7 text-dark m-1 fw-bold">El derecho a la limitación del tratamiento y el derecho de oposición</p>
                    <p className="fs-7 text-dark m-1 ">La limitación del tratamiento puede aplicarse si el interesado descubre que: </p>
                    <p className="fs-7 text-dark m-1 ">- La exactitud de los datos personales es impugnada por el usuario, durante un período que permita al responsable verificar la exactitud de los datos personales. </p>
                    <p className="fs-7 text-dark m-1 ">- El tratamiento es ilegal y el interesado se opone a la supresión de los datos personales y solicita en su lugar la limitación de su uso.  </p>
                    <p className="fs-7 text-dark m-1 ">- El responsable ya no necesita los datos personales para los fines del tratamiento, pero aún no se han borrado y son necesarios para que el interesado establezca, ejerza o defienda reclamaciones legales.  </p>
                    <p className="fs-7 text-dark m-1 ">- El interesado se ha opuesto al tratamiento pendiente de la verificación de si los motivos legítimos del responsable prevalecen sobre los del interesado. </p>
                    <p className="fs-7 text-dark m-1">La Empresa puede seguir procesando los datos personales restringidos si es necesario para establecer, ejercer o defender un derecho en los tribunales, o proteger/defender a una persona, pero solo con el consentimiento del usuario. </p>
                    <p className="fs-7 text-dark m-1">La Empresa comunicará a los destinatarios que se realizó una rectificación, supresión o limitación de los datos personales, a menos que sea imposible o implique esfuerzos desproporcionados. </p>
                    <p className="fs-7 text-dark m-1 fw-bold">El derecho a la portabilidad de datos </p>
                    <p className="fs-7 text-dark m-1 ">El usuario o un tercero indicado por él pueden recibir, previa solicitud, los datos personales procesados por la Empresa. La Empresa no asume ninguna responsabilidad por el tratamiento de datos realizado por ese tercero.  </p>
                    <p className="fs-7 text-dark m-1 ">La obligación de garantizar el derecho a la portabilidad es responsabilidad exclusiva de la Empresa solo si el tratamiento de los datos personales se basa en el consentimiento del interesado o en la conclusión y ejecución del contrato. Las acciones se tomarán dentro de los 30 días siguientes a la recepción de la solicitud. </p>
                    <p className="fs-7 text-dark m-1 fw-bold">El derecho de oposición </p>
                    <p className="fs-7 text-dark m-1 ">El usuario tendrá derecho a oponerse, por motivos relacionados con su situación particular, en cualquier momento al tratamiento de datos personales basado en el interés legítimo de la Empresa (incluida la elaboración de perfiles).</p>
                    <p className="fs-7 text-dark m-1 ">Independientemente de lo anterior, si la Empresa demuestra motivos legítimos bien justificados para el tratamiento que prevalecen sobre los intereses, derechos y libertades del interesado o para el establecimiento, ejercicio o defensa de reclamaciones legales, el tratamiento de datos puede continuar. </p>
                    <p className="fs-7 text-dark m-1 fw-bold">El derecho a presentar un reclamo  </p>
                    <p className="fs-7 text-dark m-1 ">El interesado puede presentar: </p>
                    <p className="fs-7 text-dark m-1 ">- Una solicitud / una reclamación utilizando los datos de contacto de la Empresa, según se indica en el artículo 1 anterior. </p>
                    <p className="fs-7 text-dark m-1 ">- Una acción ante el tribunal competente. </p>
                    <p className="fs-7 text-dark m-1 ">Sin embargo, la Empresa desea que cualquier conflicto/disputa se resuelva de manera amistosa y ofrece su disponibilidad en este sentido. </p>
                    <p className="fs-7 text-dark m-1 fw-bold">El derecho a retirar el consentimiento otorgado </p>
                    <p className="fs-7 text-dark m-1 ">El interesado puede retirar su consentimiento en cualquier momento, sin afectar, sin embargo, la legalidad del tratamiento antes de la retirada ni aquel basado en otros fundamentos legales.</p>
                    <p className="fs-7 text-dark m-1 fw-bold">El derecho a no ser objeto de una decisión automatizada</p>
                    <p className="fs-7 text-dark m-1 ">- La Empresa desea tomar decisiones basadas únicamente en el procesamiento automático de datos personales. </p>
                    <p className="fs-7 text-dark m-1 ">- El interesado debería tener derecho a no ser objeto de una decisión, que puede incluir una medida, evaluando aspectos personales relacionados con él que se basa únicamente en el procesamiento automatizado y que produce efectos legales concernientes a él o que afecta significativamente a él de manera similar. Tal procesamiento incluye el ‘perfilado’. Sin embargo, esto no se aplica si la decisión: (a) es necesaria para celebrar o ejecutar un contrato entre el interesado y un responsable del tratamiento; (b) está autorizada por la ley de la Unión o del Estado miembro al que está sujeto el responsable y que también establece medidas adecuadas para salvaguardar los derechos y libertades del interesado y sus intereses legítimos; o (c) se basa en el consentimiento explícito del interesado.  </p>
                    <p className="fs-7 text-dark m-1 ">- Predicciones basadas en los Datos del Usuario ("profiling"). </p>
                    <p className="fs-7 text-dark m-1 ">- La Empresa puede usar los datos personales recopilados a través de UPBIRK para crear o actualizar perfiles de usuario. Este tipo de procesamiento de datos permite a la Empresa evaluar las elecciones, preferencias y comportamientos del usuario para los fines descritos en la sección respectiva de este documento.
                    </p>
                    <p className="fs-7 text-dark m-1 ">- Los perfiles de usuario también pueden crearse usando herramientas automatizadas como algoritmos, que también pueden proporcionar terceros.</p>
                    <p className="fs-7 text-dark m-1 ">- El Usuario siempre tiene derecho a oponerse a este tipo de actividad de perfilado. Para obtener más información sobre los derechos del Usuario y cómo ejercerlos, se invita al Usuario a consultar el art. 11 letra g de este documento.</p>
                    <p className="fs-7 text-dark m-1 ">- La toma de decisiones automatizada significa que una decisión que probablemente tenga efectos legales o efectos significativos similares en el usuario se toma únicamente por medios tecnológicos, sin intervención humana. UPBIRK puede usar los datos personales del usuario para tomar decisiones totalmente o parcialmente basadas en procesos automatizados según los fines descritos en este documento. La Empresa adopta procesos de toma de decisiones automatizados en la medida en que sea necesario para celebrar o ejecutar un contrato entre el Usuario y el Propietario, o basado en el consentimiento explícito del usuario, cuando dicho consentimiento sea requerido por la ley. </p>
                    <p className="fs-7 text-dark m-1 ">- Las decisiones automatizadas se toman mediante medios tecnológicos, basados en algoritmos sujetos a criterios predefinidos, que también pueden proporcionar terceros. </p>
                    <p className="fs-7 text-dark m-1 ">La razón detrás de la toma de decisiones automatizada es:</p>
                    <p className="fs-7 text-dark m-1 ">- Permitir o mejorar el proceso de toma de decisiones. </p>
                    <p className="fs-7 text-dark m-1 ">- Otorgar a los usuarios un trato justo e imparcial basado en criterios consistentes y uniformes. </p>
                    <p className="fs-7 text-dark m-1 ">- Reducir el daño potencial derivado del error humano, el sesgo personal, y similares que pueden conducir potencialmente a la discriminación o desequilibrio en el tratamiento de individuos, etc. </p>
                    <p className="fs-7 text-dark m-1 ">- Reducir el riesgo de que los usuarios no cumplan con su obligación en virtud de un contrato.  </p>
                    <p className="fs-7 text-dark m-1 ">Así, los usuarios tienen derecho a ejercer derechos específicos para prevenir o limitar los efectos potenciales de las decisiones automatizadas tomadas. </p>
                    <p className="fs-7 text-dark m-1 ">En particular, los usuarios tienen derecho a:</p>
                    <p className="fs-7 text-dark m-1 ">- Obtener una explicación sobre cualquier decisión tomada por la Empresa como resultado de la toma de decisiones automatizadas y expresar su punto de vista sobre esta decisión. </p>
                    <p className="fs-7 text-dark m-1 ">- Impugnar una decisión solicitando al Propietario que la reconsidere o tome una nueva decisión sobre una base diferente. </p>
                    <p className="fs-7 text-dark m-1 ">- Solicitar y obtener del Propietario intervención humana en dicho tratamiento. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Principales obligaciones del interesado </h5>
                    <p className="fs-7 text-dark m-1 fw-bold">Confidencialidad </p>
                    <p className="fs-7 text-dark m-1 ">- El interesado tiene la obligación de mantener la confidencialidad de todos los datos personales con los que entre en contacto en relación con la Empresa. </p>
                    <p className="fs-7 text-dark m-1 ">- El hecho de que un usuario pueda dar acceso a un perfil de otro usuario de la plataforma UPBIRK, en ciertas condiciones, no se considerará como una violación de la obligación mencionada anteriormente. Los usuarios declaran que son conscientes de este hecho y no tendrán reclamaciones al respecto hacia la Empresa. </p>
                    <p className="fs-7 text-dark m-1 fw-bold">Cumplimiento de las medidas de seguridad de datos  </p>
                    <p className="fs-7 text-dark m-1 ">- El interesado no deberá procesar datos confidenciales ni datos personales de terceros a menos que sea absolutamente necesario, se asegure la confidencialidad y se cumpla plenamente con la legislación específica. </p>
                    <p className="fs-7 text-dark m-1 ">- En caso de incumplimiento de las obligaciones indicadas en este artículo 12 por parte del interesado, la Empresa estará facultada para obtener de él una compensación por todos los daños sufridos.  </p>
                  </div>
                  <div className="pb-5">
                    <h5>Obligaciones de la Empresa </h5>
                    <p className="fs-7 text-dark m-1 fw-bold">Medidas de seguridad aplicables a los datos personales procesados </p>
                    <p className="fs-7 text-dark m-1 ">La Empresa ha cumplido con las disposiciones de la legislación de protección de datos e ha implementado medidas técnicas y organizativas apropiadas para garantizar la seguridad de los datos personales procesados y los derechos de los usuarios. Así, la Empresa ha implementado medidas como: </p>
                    <p className="fs-7 text-dark m-1 ">- La conclusión de contratos con colaboradores que han asumido la obligación de confidencialidad en relación con los datos personales procesados, y la obligación de cumplir con la legislación aplicable en protección de datos personales.  </p>
                    <p className="fs-7 text-dark m-1 ">- La formación de los empleados y colaboradores sobre la importancia de la protección de datos personales y la limitación de su acceso a los datos según sus atribuciones y competencias. </p>
                    <p className="fs-7 text-dark m-1 ">- El establecimiento de procedimientos internos para proteger los datos personales. </p>
                    <p className="fs-7 text-dark m-1 ">- La indicación de los datos de contacto que pueden utilizarse para preguntas/reclamaciones sobre los datos personales (los indicados en el artículo 1 de la presente política). </p>
                    <p className="fs-7 text-dark m-1 ">- La implementación de medidas de seguridad de la información. </p>
                    <p className="fs-7 text-dark m-1 ">- El procesamiento de datos se lleva a cabo utilizando computadoras y herramientas habilitadas para IT, siguiendo procedimientos organizativos y modos estrictamente relacionados con los propósitos indicados. </p>
                    <p className="fs-7 text-dark m-1 ">- No se instalan cookies y las necesarias para el funcionamiento de UPBIRK y se ofrece a los usuarios la posibilidad de elegir las adicionales aceptadas.</p>
                    <p className="fs-7 text-dark m-1 ">Además, la Empresa informará a la autoridad competente de protección de datos en caso de una violación relacionada con la seguridad de los datos, sin demora indebida y, si es posible, dentro de las 72 horas a partir del momento en que tuvo conocimiento de ella a menos que sea poco probable que cree un riesgo para los derechos y libertades de las personas. Si la notificación a la autoridad no se realiza dentro de las 72 horas, deberá ir acompañada de una explicación justificada por el retraso. </p>
                    <p className="fs-7 text-dark m-1 ">En caso de un incidente relacionado con la seguridad de los datos personales, la Empresa también informará al interesado sin demora indebida, si la violación de la seguridad de los datos personales es probable que genere un alto riesgo para sus derechos y libertades. Sin embargo, los usuarios no necesariamente serán informados si se cumplen alguna de las siguientes condiciones:</p>
                    <p className="fs-7 text-dark m-1 ">- La Empresa ha implementado medidas de protección técnicas y organizativas adecuadas, y estas medidas se han aplicado en el caso de los datos personales afectados por la violación de seguridad. </p>
                    <p className="fs-7 text-dark m-1 ">- La Empresa ha tomado medidas adicionales para garantizar que el alto riesgo para los derechos y libertades de los interesados ya no sea probable que ocurra. </p>
                    <p className="fs-7 text-dark m-1 ">- Requeriría un esfuerzo desproporcionado. En esta situación, en su lugar se realizará un aviso público o se tomará una medida similar, para que se informe eficazmente a los interesados. </p>
                    <p className="fs-7 text-dark m-1 ">Cualquier estadística sobre el tráfico de los usuarios en UPBIRK, que la Empresa proporcionará a redes publicitarias de terceros u otros sitios, tendrá una forma de conjunto de datos y no incluirá información identificable sobre ningún usuario individual. </p>
                    <p className="fs-7 text-dark m-1 ">Lamentablemente, ninguna transmisión de datos a través de Internet puede garantizarse al 100% segura. En consecuencia, a pesar de los esfuerzos de la Empresa para proteger los datos personales de los usuarios, no puede garantizar o asegurar la seguridad de la información transmitida por ellos a través de UPBIRK. Por lo tanto, se advierte a los usuarios que cualquier información enviada a través del entorno en línea se realiza bajo su propio riesgo. Para mitigar este riesgo, una de las medidas tomadas por la Empresa es ofrecer a todos los usuarios interesados la posibilidad de enviar solicitudes/direcciones/mensajes en forma material, a la sede de la Empresa, y no necesariamente a través del "Contacto" o "Chat". </p>
                  </div>
                  <div className="pb-5">
                    <h5>Subprocesamiento</h5>
                    <p className="fs-7 text-dark m-1 ">La Empresa no designará ni revelará ningún dato personal del usuario a ningún Contratista o Subprocesador a menos que sea esencial para proporcionar funcionalidad a los usuarios de UPBIRK, y solo después de que el Contratista/Subprocesador acepte cumplir con los mismos términos de protección de datos y políticas con respecto al uso de datos personales recopilados por UPBIRK.  </p>
                    <p className="fs-7 text-dark m-1 ">A partir de la fecha de esta política, los únicos Contratistas con acceso a datos personales de usuarios son los empleados y contratistas de desarrollo de software de la Empresa. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Responsabilidad de la Empresa </h5>
                    <p className="fs-7 text-dark m-1 ">La responsabilidad de la Empresa con respecto al interesado se establecerá en relación con la calidad mantenida en la respectiva operación de procesamiento de datos, la razón y el lugar del incidente, las medidas de seguridad tomadas, las medidas tomadas para evitar incidentes y el cumplimiento de las demás obligaciones legales y políticas publicadas en UPBIRK. </p>
                    <p className="fs-7 text-dark m-1 fw-bold">Lugar de procesamiento.</p>
                    <p className="fs-7 text-dark m-1">Transferencia de datos personales a terceros países/organizaciones internacionales</p>
                    <p className="fs-7 text-dark m-1 ">- Los datos personales se procesan en las oficinas operativas del Propietario en Rumania y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. </p>
                    <p className="fs-7 text-dark m-1 ">- Dependiendo de la ubicación del usuario, las transferencias de datos pueden implicar la transferencia de los datos del usuario a un país que no sea el suyo. Para obtener más información sobre el lugar de procesamiento de dichos Datos transferidos, los Usuarios pueden consultar la sección que contiene detalles sobre el procesamiento de Datos personales.</p>
                    <p className="fs-7 text-dark m-1 ">- Los Usuarios también tienen derecho a conocer la base legal de las transferencias de Datos a un país fuera de la Unión Europea o a cualquier organización internacional regida por el derecho internacional público o establecida por dos o más países, como la ONU, y sobre las medidas de seguridad tomadas por el Propietario para salvaguardar sus datos.</p>
                    <p className="fs-7 text-dark m-1 ">- Si se realiza alguna transferencia de este tipo, los usuarios pueden obtener más información consultando las secciones relevantes de este documento o preguntando al Propietario utilizando la información proporcionada en el formulario de Contacto o Chat. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Disposiciones Finales </h5>
                    <p className="fs-7 text-dark m-1 ">- Esta política se aplica a la Empresa y a los usuarios de UPBIRK (incluidos aquellos que completan los formularios existentes en UPBIRK).</p>
                    <p className="fs-7 text-dark m-1 ">- Este documento es parte del conjunto de políticas de seguridad de la Empresa. Otras políticas pueden aplicarse a los temas abordados aquí y pueden ser revisadas según necesidades específicas.</p>
                    <p className="fs-7 text-dark m-1 ">- Además de la información contenida en esta política de privacidad, UPBIRK puede proporcionar al usuario información adicional y contextual sobre servicios particulares o el procesamiento de datos personales a pedido. Consulte la información de contacto al principio de este documento.</p>
                  </div>
                </div>
                {/*  .nl-entry  */}
              </div>
              {/*  .nl-entry-wrap  */}
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Terms Section End  */}

      {/*  CTA Section Start  */}
      <NioSection>
        <NioSection.Content>
          <CallToAction />
        </NioSection.Content>
      </NioSection>
      {/*  CTA Section End  */}

      {/*  CTA Section End  */}



    </AppLayout>
  )
}

export default index;