import React from 'react';
import { Col, Container, Row} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
// layout
import AppLayout from '../../layouts/AppLayout/AppLayout';

// components
import {  NioSection, NioCard} from '../../components';

// section content 

import CallToAction from '../../components/PageComponents/SectionComponents/CallToAction/CallToAction';

function index() {

  return (
    <AppLayout title="Descubre Nuestras Herramientas para la Creación de tu Proyecto" layoutClass="3">

       <Helmet>
          <title>Frameworks: Descubre Nuestras Herramientas para la Creación de tu Proyecto | Upbirk</title>
          <meta name='description' content='Hemos digitalizado las herramientas esenciales para proyectos de innovación, facilitándote el entendimiento del problema'/>
          <link rel='canonical' href='/Frameworks'/>
      </Helmet>


      <section className="nl-section nl-banner nl-banner-analytics overflow-hidden bg-primary-gradient-soft pb-0">
        <div className="nl-banner-wrap pb-0 pb-md-0 overflow-hidden">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="9">
                <div className="nl-banner-content text-center">
                  <h3 className="display-4 m-0 pb-5">Descubre los Frameworks disponibles para crear tu <span style={{color:"#3680BC"}}>proyecto</span></h3>
                  <p className="fs-5 ">Hemos digitalizado las herramientas esenciales para proyectos de innovación, facilitándote el entendimiento del problema, el descubrimiento del cliente, la valoración del mercado y la definición de soluciones, todo esto te permitirá abordar los desafíos en innovación de forma más efectiva y eficiente.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <NioSection className="pt-8 pb-8">
        <NioSection.Content>
          <Row className="gy-5">
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                    <img src='images/icon-framework/Modelo canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt=""/>
                    <div className="m-0">
                      <h4 className="text-capitalize">Modelo Canvas</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">                 
                    <img src='images/icon-framework/Mapa de empatía_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1'alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Mapa de empatia</h4>
                      <p className="fs-7">Descubrimiento del Cliente</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Entrevista insights_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Entrevista Insights</h4>
                      <p className="fs-7">Descubrimiento del Cliente</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Customer statement_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Customer statement</h4>
                      <p className="fs-7">Descubrimiento del Cliente</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Buyer persona_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Buyer persona</h4>
                      <p className="fs-7">Descubrimiento del Cliente</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Interview canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Interview Canvas</h4>
                      <p className="fs-7">Descubrimiento del Cliente</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Interview setup_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Interview Setup</h4>
                      <p className="fs-7">Descubrimiento del Cliente</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Hoja de ruta_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Hoja de Ruta</h4>
                      <p className="fs-7">Planificación</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Flujo de caja_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Flujo de caja</h4>
                      <p className="fs-7">Planificación</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Flujo de caja_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Plan de actividades</h4>
                      <p className="fs-7">Planificación</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Flujo de caja_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Definición del problema</h4>
                      <p className="fs-7 ">Entendimiento del Problema</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Flujo de caja_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Product Definition</h4>
                      <p className="fs-7 ">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Marketing canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Marketing Canvas</h4>
                      <p className="fs-7  ">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Lienzo de propuesta de valor_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">propuesta de valor</h4>
                      <p className="fs-7  ">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Lean canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Lean Canvas</h4>
                      <p className="fs-7  ">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Analisis de la Competencia_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Análisis Competencia</h4>
                      <p className="fs-7 ">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Modelo lean UX_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Modelo Lean UX</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Problem solution canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Problem Solution</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Pricing canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Pricing Canvas</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Misión y visión_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Misión y Visión</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/PESTEL_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">PESTEL</h4>
                      <p className="fs-7">Valorización de mercado</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Segmento de mercado_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Segmento de mercado</h4>
                      <p className="fs-7">Valorización de mercado</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Target market segment_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Target Market Segment</h4>
                      <p className="fs-7">Valorización de mercado</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/S.W.O.T_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">S.W.O.T</h4>
                      <p className="fs-7">Valorización de mercado</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/C.A.G.E_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">C.A.G.E</h4>
                      <p className="fs-7">Valorización de mercado</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Root cause analysis (5 why)_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">RCA (5 why)</h4>
                      <p className="fs-7">Entendimiento del Problema</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Problem statement_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Problem Statement</h4>
                      <p className="fs-7">Entendimiento del Problema</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Problem prioritization canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Problem Priorization</h4>
                      <p className="fs-7">Entendimiento del Problema</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Presupuesto_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Presupuesto</h4>
                      <p className="fs-7">En plataforma</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Bitácora de actividades_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize" >Bitácora de Actividades</h4>
                      <p className="fs-7">En plataforma</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
                <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"38px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="p-5">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Tam sizing_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="mt-1">                   
                      <h4 className="text-capitalize">Tam Sizing </h4>
                      <p className="fs-7">Valorización del Mercado</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4 ">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"38px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/MVP Experiment Canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="mt-1">
                      <h4 className="text-capitalize">MVP Canvas</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"38px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/StoryTelling Canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="mt-1">
                      <h4 className="text-capitalize">StoryTelling</h4>
                      <p className="fs-7">Descubrimiento del Cliente</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"38px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Experiment Canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="mt-1">
                      <h4 className="text-capitalize">Experiment Canvas</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"38px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/AARRR Pirate Metrics_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="mt-1">
                      <h4 className="text-capitalize">Pirate Metrics</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"38px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Opportunity Canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="mt-1">
                      <h4 className="text-capitalize">Opportunity</h4>
                      <p className="fs-7">Entendimiento del Problema</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"34px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Creative Matrix_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Creative Matrix</h4>
                      <p className="fs-7">Entendimiento del Problema</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"34px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Team Canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Team Canvas</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"34px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Pricing Comparison Canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Pricing Comparison</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"34px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Solution Canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Solution Canvas</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"34px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Problem Framing Canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Problem Framing</h4>
                      <p className="fs-7">Entendimiento del Problema</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"32px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Estado Arte_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Estado Arte</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"34px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Validation Canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Validation Canvas</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"34px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/TRL Canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">TRL Canvas</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"32px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/The Impact Canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">The Impact Canvas</h4>
                      <p className="fs-7">Descubrimiento del Cliente</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"38px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Innovation Space Generator_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="mt-1">
                      <h4 className="text-capitalize">Innovation Space</h4>
                      <p className="fs-7">Descubrimiento del Cliente</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"38px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Problem Sizing Canvas_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="mt-1">
                      <h4 className="text-capitalize">Problem Sizing</h4>
                      <p className="fs-7">Entendimiento del Problema</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"34px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Build it, Break it, fix it_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="mt-1">
                      <h4 className="text-capitalize">Build, Break, fix</h4>
                      <p className="fs-7">Entendimiento del Problema</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"34px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Marketing Plan_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Marketing Plan</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"32px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/Lean Startup_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">Lean Startup</h4>
                      <p className="fs-7">Definición de la Solución</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="4" >
              <NioCard className="border-4">
              <div className='position-absolute top-0 end-0'style={{marginRight:"15px", marginTop:"32px"}}>
                <span class="badge" style={{background:"#3680BC", color:"white", fontSize:"12px"}}>Soon</span>
                </div>
                <NioCard.Body className="">
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                  <img src='images/icon-framework/OKR_SVG.svg' style={{ backgroundColor: "#3680BC", width: "15%"}} className='rounded p-1' alt='' />
                    <div className="m-0">
                      <h4 className="text-capitalize">OKR</h4>
                      <p className="fs-7">Planificación</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>

      <section className="">
        <div className="pb-8">
          <Container>
            <Row className="justify-content-center m-auto">
              <Col lg="10" xl="9">
                <div className="nl-banner-content text-center">
                  <h3 className="display-5 m-0 pb-5">En busqueda constante de <span style={{color:"#3680BC"}}>nuevas herramientas</span></h3>
                  <p className="fs-5">Nuestros ingenieros se mantienen en constante estudio de las últimas tendencias dentro del ecosistema de la innovación tecnológica, con el objetivo de identificar las nuevas herramientas y frameworks que van emergiendo para garantizar a nuestros usuarios que tendrán acceso a las soluciones más avanzadas y eficaces para el desarrollo de sus proyectos</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <NioSection className="pt-0 pb-8">
        <NioSection.Content>
          <CallToAction />
        </NioSection.Content>
      </NioSection>
    </AppLayout >
  )
}

export default index