import React from 'react';

import { Col, Row } from 'react-bootstrap';

// layout 
import AppLayout from '../../layouts/AppLayout/AppLayout';

// section content 
import { NioSection } from '../../components';
import CallToAction from '../../components/PageComponents/SectionComponents/CallToAction/CallToAction';

function index() {
  return (
    <AppLayout layoutClass="3" title="Terminos y Condiciones">

      {/*  Terms Section Start  */}
      <NioSection className="pt-120 pt-lg-180 ">
        <div className="nl-block-head md">
          <div className="nl-section-head pb-0">
            <h2 className='text-center'>Términos y Condiciones</h2>
          </div>
        </div>
        <NioSection.Content>
          <Row className="pt-3 justify-content-center">
            <Col lg="8">
              <div className="nl-entry-wrap pt-3 ps-lg-5">
                <div className="nl-entry" style={{textAlign:"justify"}}>
                  <div className="pb-5">
                    <h5>Términos y Condiciones</h5>
                    <p className="fs-7 text-dark">Estos términos y condiciones detallan las prácticas y condiciones comerciales de INNOVACIONES Y DESARROLLOS TECNOLÓGICOS UPBIRK SPA para el sitio web y la aplicación alojados en el dominio UPBIRK.COM y sus subdominios (en adelante denominados UPBIRK.COM el Sitio/Sitio web, respectivamente, Aplicación/Aplicaciones) y tienen la intención de informar a los usuarios sobre este tema. Al utilizar UPBIRK, los usuarios reconocen que han tomado conocimiento y aceptan estos Términos y Condiciones, la Política de Cookies y la Política de Privacidad y Protección de Datos publicadas en UPBIRK.</p>
                    <p className="fs-7 text-dark">
                    Última actualización: 25 de enero de 2024 
                    </p>
                    <p className="fs-7 text-dark">
                    Identificación del responsable del tratamiento de datos 
                    </p>
                    <p className="fs-7 text-dark">
                    <b>Nombre:</b> INNOVACIONES Y DESARROLLOS TECNOLÓGICOS UPBIRK SPA (en adelante, la Empresa, el Propietario o UPBIRK SPA)    
                    </p>
                    <p className="fs-7 text-dark">
                    <b>Dirección:</b> 1 NORTE 461 OF 703 VERGARA VINA DEL MAR, CHILE.    
                    </p>
                    <p className="fs-7 text-dark">
                    <b>Rut:</b> 77.381.573-9    
                    </p>
                    <p className="fs-7 text-dark">
                    <b>Correo electrónico:</b> office@upbirk.com    
                    </p>
                  </div>
                  <div className="pb-5">
                    <h5>Datos de contacto en el ámbito de protección de datos personales </h5>
                    <p className="fs-7 text-dark">Cualquier solicitud, notificación o reclamación relacionada con estos Términos y Condiciones, la Política de Privacidad y Protección de Datos, la Política de Cookies, políticas u operaciones realizadas por la Empresa, así como cualquier otra información publicada en UPBIRK, puede enviarse a los datos de contacto indicados anteriormente. El plazo para que la Empresa envíe una respuesta es de no más de 30 días a partir de la recepción de la solicitud.</p>
                  </div>
                  <div className="pb-5">
                    <h5>Consentimiento de los usuarios de UPBIRK </h5>
                    <p className="fs-7 text-dark m-1">- Estos términos y condiciones son asumidos por la Empresa, pero también por los usuarios de UPBIRK (es decir, usted) ya sea registrado o no.</p>
                    <p className="fs-7 text-dark m-1">- El uso de UPBIRK implica automáticamente la aceptación de todos los términos y condiciones establecidos a continuación y de todas las políticas a las que se hace referencia (cuando la ley no exige pasos adicionales para expresar dicha aceptación), y asumiendo la obligación de que el usuario tenga el derecho y la capacidad libre y plena de acceder a UPBIRK y de proporcionar información a la Empresa. </p>
                    <p className="fs-7 text-dark m-1">- Sin embargo, si el usuario no acepta o está en desacuerdo con todos los términos, condiciones y políticas mencionadas, no debe usar UPBIRK (el Sitio o la Aplicación). </p>
                    <p className="fs-7 text-dark m-1">- El acceso al Sitio de UPBIRK es completamente gratuito para cualquier visitante, sin la necesidad de crear una cuenta de usuario. </p>
                    <p className="fs-7 text-dark m-1">- Sin embargo, si el usuario tiene la intención de beneficiarse realmente de la Aplicación/Apps de UPBIRK, no solo para ver la información y los servicios ofrecidos por la Empresa, primero debe registrarse como usuario y crear una cuenta individual. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Derechos de propiedad intelectual </h5>
                    <p className="fs-7 text-dark m-1">- La Empresa o sus proveedores son propietarios de los derechos de propiedad intelectual sobre todos los componentes protegibles de UPBIRK, incluidos, entre otros, los nombres de UPBIRK y de la Empresa, el eslogan Data Beats Gut Feeling, diseños, obras de arte, elementos de interfaz de usuario final, código de software, contenido escrito, flujos de trabajo, procesos, características individuales y cualquier documentación relacionada. Los usuarios no pueden copiar, modificar, adaptar, reproducir, distribuir, descompilar o desensamblar ningún aspecto de UPBIRK sin la aprobación escrita de la Empresa. </p>
                    <p className="fs-7 text-dark m-1">- Específicamente, los usuarios no pueden copiar, extraer, reproducir o distribuir datos del Sitio/Aplicación para fines personales, educativos o comerciales sin la aprobación escrita de la Empresa. </p>
                    <p className="fs-7 text-dark m-1">- Los usuarios de UPBIRK no deben actuar de manera que pueda infringir los derechos de la Empresa y se comprometen a no utilizar ningún signo, símbolo, nombre, eslogan, diseño, texto escrito, flujo de trabajo o proceso similar o idéntico a los utilizados por la Empresa en UPBIRK (incluidos, entre otros, "UPBIRK" y "la Empresa").</p>
                    <p className="fs-7 text-dark m-1">- Además, la Empresa no será responsable de ninguna manera por la manera en que algunos usuarios utilicen cualquier información (incluso datos personales) de UPBIRK, incluidos los datos comerciales personales o de terceros.</p>
                    <p className="fs-7 text-dark m-1">- Además, la Empresa no será responsable de ninguna acción del usuario en UPBIRK que pueda afectar de alguna manera un derecho de propiedad intelectual o un derecho de privacidad de datos de un tercero, siendo el usuario el único responsable.</p>
                    <p className="fs-7 text-dark m-1">- La Empresa no reclama derechos de propiedad intelectual sobre el contenido que los usuarios cargan o proporcionan utilizando UPBIRK. Sin embargo, al utilizar UPBIRK para publicar y enviar contenido, los usuarios aceptan que otros puedan ver y compartir su contenido dentro de los límites que pueden configurar para todos los tipos de información. Cualquier información que el usuario no restrinja en su perfil (asignando un nivel de privacidad específico como Privado, Acceso Aprobado, Solo Equipo u otros) se considera, de otro modo, por defecto, como Pública. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Condiciones comerciales generales </h5>
                    <p className="fs-7 text-dark m-1">- El uso de UPBIRK, incluido cualquier contenido, información o funcionalidad contenida en él, se proporciona "tal cual" y "según esté disponible" sin representaciones o garantías de ningún tipo, ya sean expresas o implícitas, incluidas, entre otras, las garantías implícitas de comerciabilidad, idoneidad para un fin particular y no infracción. Los usuarios asumen la responsabilidad total y el riesgo por su uso de UPBIRK. </p>
                    <p className="fs-7 text-dark m-1">- Los usuarios comprenden que UPBIRK puede utilizarse para la transmisión de su contenido personal o comercial y que, durante el procesamiento, dicho contenido se transferirá cifrado a través de Internet.</p>
                    <p className="fs-7 text-dark m-1">- Los usuarios comprenden que la Empresa utiliza proveedores externos y socios de alojamiento para proporcionar el hardware, software, datos e información, redes, almacenamiento y tecnología relacionada necesaria para ejecutar el servicio. .</p>
                    <p className="fs-7 text-dark m-1">- Los usuarios acuerdan no revender, duplicar, reproducir o explotar ninguna parte de UPBIRK sin el permiso explícito por escrito de la Empresa.</p>
                    <p className="fs-7 text-dark m-1">- Los usuarios no pueden utilizar UPBIRK para almacenar, alojar o enviar correos electrónicos no solicitados (spam), chats o cualquier tipo de comunicación no solicitada a otros usuarios u organizaciones en la plataforma. La Empresa cumple con la normativa contra el spam y no autoriza ni permite el envío de comunicaciones no solicitadas a través del servicio de automatización por parte de los usuarios. Si hay evidencia de spam, los servicios podrían suspenderse sin previo aviso. El spam accidental debe reportarse inmediatamente a la Empresa para evitar la suspensión de la cuenta. </p>
                    <p className="fs-7 text-dark m-1">- Los usuarios no pueden utilizar el servicio para transmitir virus, gusanos o contenido malicioso. </p>
                    <p className="fs-7 text-dark m-1">- La Empresa no ofrece garantías con respecto a:
                    <lu >
                        <li className='m-1'>
                        a capacidad de los usuarios para utilizar UPBIRK, 
                        </li>
                        <li className='m-1'>
                        la satisfacción de los usuarios con UPBIRK y sus funcionalidades, 
                        </li>
                        <li className='m-1' >
                        que UPBIRK estará disponible en todo momento, ininterrumpido y sin errores,  
                        </li>
                        <li className='m-1'>
                        a precisión de los datos en UPBIRK, 
                        </li>
                        <li className='m-1'>
                        la precisión de los cálculos matemáticos realizados por UPBIRK/la Empresa, y  
                        </li>
                        <li className='m-1'>
                        que los errores o problemas en UPBIRK serán corregidos.  
                        </li>
                    </lu>
                    </p>
                    <p className="fs-7 text-dark m-1">- La Empresa, sus afiliadas y sus patrocinadores no son responsables ni serán responsables de ningún daño directo, indirecto, incidental, consecuencial, especial, ejemplar, punitivo u otros que surjan de alguna manera relacionada con el uso de UPBIRK por parte de los usuarios. El único remedio para los usuarios insatisfechos con UPBIRK es dejar de usar el Sitio/Aplicación. </p>
                    <p className="fs-7 text-dark m-1">- La Empresa puede, pero no tiene la obligación de, eliminar contenido y cuentas que contengan contenido que la Empresa determine, a su exclusivo criterio, que son ilegales, ofensivas, amenazantes, difamatorias, pornográficas, obscenas o de cualquier otra manera objetable o que violen los derechos de propiedad intelectual de cualquier parte o estos términos. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Términos de la cuenta </h5>
                    <p className="fs-7 text-dark m-1">- Los usuarios deben ser humanos. No se permiten cuentas registradas por bots y otros métodos automatizados. </p>
                    <p className="fs-7 text-dark m-1">- Los usuarios deben proporcionar su nombre legal completo y una dirección de correo electrónico válida o una cuenta social para completar el proceso de registro. Las cuentas falsas se eliminarán inmediatamente cuando sean detectadas. </p>
                    <p className="fs-7 text-dark m-1">- Los usuarios pueden crear organizaciones y agregar información confidencial (incluidas contraseñas, datos personales como métricas, transacciones de clientes, nombres, etc.), bajo la condición de que se cumpla la presente política y también la Política de Privacidad. Cláusulas de adición de organizaciones. </p>
                    <p className="fs-7 text-dark m-1">- La Empresa puede comunicarse con los usuarios por correo electrónico con respecto a su cuenta, actualizaciones, noticias y otros asuntos relacionados con su cuenta. Cuando los usuarios se registran en nuestra plataforma, se suscriben automáticamente a nuestra lista de correo, ya que esto es esencial para compartir información importante sobre sus cuentas y el uso de UPBIRK. Los usuarios no pueden optar por no recibir actualizaciones importantes del sistema (como cambios de contraseña, notificaciones de suscripción, invitaciones a otros grupos o cuentas), pero pueden optar por no recibir actualizaciones de marketing haciendo clic en los enlaces para darse de baja en el pie de página de cada notificación por correo electrónico, o en su configuración de cuenta personal/organizacional (sin embargo, el procesamiento de sus datos personales con fines de marketing cumplirá con la regla de consentimiento explícito y no el de optar por no participar). </p>
                    <p className="fs-7 text-dark m-1">- Los usuarios son responsables de mantener la seguridad de sus cuentas y contraseñas. La Empresa no puede y no será responsable de ninguna pérdida o daño derivado del incumplimiento por parte de los usuarios de esta obligación de seguridad. La Empresa no almacena contraseñas en los servidores de UPBIRK, por lo que es imposible que terceros accedan a las contraseñas de los usuarios. </p>
                    <p className="fs-7 text-dark m-1">- Los usuarios son responsables de todo el contenido publicado y de la actividad que ocurre bajo su cuenta (incluso si el contenido es generado por el usuario), ya sea en UPBIRK, mensajes generados por su cuenta de UPBIRK, mensajes de ayuda, llamadas telefónicas o publicaciones en redes sociales. </p>
                    <p className="fs-7 text-dark m-1">- La Empresa tiene un límite suave en todos sus planes y, si consideramos que los usuarios están abusando de los servicios ofrecidos, podemos suspender temporalmente cualquier cuenta. La Empresa buscará la cooperación de los usuarios al respecto. </p>
                    <p className="fs-7 text-dark m-1">- La Empresa se reserva el derecho de aceptar o rechazar a cualquier usuario potencial y tiene la discreción exclusiva sobre si permitir que un usuario se registre, renueve, cambie de plan o use UPBIRK. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Términos de Pagos, Reembolsos, Actualizaciones y Descensos </h5>
                    <p className="fs-7 text-dark m-1">- Cuentas de organizaciones gratuitas: UPBIRK ofrece algunos servicios a algunos usuarios de forma gratuita. </p>
                    <p className="fs-7 text-dark m-1">- Cuentas de organizaciones pagas: UPBIRK ofrece algunos servicios premium utilizando un modelo de suscripción de pago según el uso (SaaS), ya sea mensual o anual. La Empresa facturará a la organización del usuario por cada período de suscripción (mensual o anual) por adelantado. Dado que la Empresa ofrece precios muy competitivos, no ofrece reembolsos en los planes de suscripción. Los usuarios pueden cancelar el servicio en cualquier momento y no se les cobrará de nuevo, pero si la cancelación ocurre durante el período cubierto por el pago, no se realizará ningún reembolso parcial o total del pago. </p>
                    <p className="fs-7 text-dark m-1">- Los precios mostrados en el sitio web no incluyen impuestos. Los usuarios son responsables de los impuestos adicionales (IVA), cargos por tarjeta de crédito, comisiones por cambio de divisa o cualquier. otro cargo adicional que pueda incurrir. </p>
                    <p className="fs-7 text-dark m-1">- Se requiere una tarjeta de crédito válida para las cuentas de pago.</p>
                    <p className="fs-7 text-dark m-1">- Para cualquier actualización o descenso de planes, se reembolsará a los usuarios por el período de suscripción restante, y la suscripción se renovará por un período de suscripción similar (mes o año) bajo las nuevas condiciones (plan, número de asientos, funciones, etc.). </p>
                    <p className="fs-7 text-dark m-1">- La actualización o el descenso de planes en UPBIRK puede provocar la pérdida de contenido, funciones o capacidad de la cuenta de usuario u organización. La Empresa no acepta ninguna responsabilidad por tales pérdidas. </p>
                    <p className="fs-7 text-dark m-1">- Debido a la política de igualdad para nuestros clientes, no se harán excepciones. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Cancelación y Terminación </h5>
                    <p className="fs-7 text-dark m-1">- El propietario de la cuenta es la única persona que puede cancelar una cuenta. Por lo tanto, cada usuario es el único responsable de cancelar adecuadamente su cuenta individual o la cuenta de su organización.  </p>
                    <p className="fs-7 text-dark m-1">- Los usuarios deben asegurarse de que las solicitudes de cancelación se realicen únicamente a través del formulario de contacto en la plataforma.  </p>
                    <p className="fs-7 text-dark m-1">- La empresa tarda unos 5 días hábiles en procesarlas. Una vez que se cancela una cuenta, toda la información de la cuenta se eliminará permanentemente. Ese usuario no será cobrado nuevamente después de la fecha oficial de cancelación. La Empresa no es responsable de ninguna pérdida de datos resultante de la cancelación de cuentas de usuario u organizaciones. </p>
                    <p className="fs-7 text-dark m-1">- La Empresa, a su exclusivo criterio, tiene el derecho de suspender o terminar la cuenta de UPBIRK de un usuario y rechazar cualquier uso actual o futuro de sus servicios, por cualquier motivo, en cualquier momento. Dicha terminación o suspensión resultará en la eliminación o desactivación temporal de la cuenta de UPBIRK de ese usuario, junto con la pérdida y renuncia de todo el contenido de esa cuenta. La Empresa se reserva el derecho de rechazar el servicio a cualquier persona por cualquier motivo en cualquier momento, sin embargo, sin basar tal acción en motivos discriminatorios. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Datos personales </h5>
                    <p className="fs-7 text-dark m-1">- Para obtener información detallada sobre cómo se procesan dichos datos, revise la Política de Privacidad y Protección de Datos. </p>
                    <p className="fs-7 text-dark m-1">- La Empresa recopila información de visitantes/usuarios de cuatro maneras principales: Directamente: La recopilación de datos se realiza al momento de acceder a UPBIRK y completar el formulario de registro y, posteriormente, al ingresar información adicional de perfil personal y/o organizacional y datos de pago, bajo la condición de marcar la casilla de aceptación de la Política de Privacidad y Protección de Datos. El procesamiento se hará según las disposiciones de la Política de Privacidad y Protección de Datos. </p>
                    <p className="fs-7 text-dark m-1">- Información obtenida de los informes de tráfico registrados por el servidor de UPBIRK u otras herramientas de seguimiento: cuando se accede a UPBIRK, los usuarios divulgan automáticamente cierta información, como la dirección IP, la hora de la visita y el lugar desde donde se accedió al sitio web. La Empresa, como otros operadores, registra esta información y la procesa de acuerdo con la Política de Privacidad y Protección de Datos.</p>
                    <p className="fs-7 text-dark m-1">- Información obtenida a través de cookies: UPBIRK utiliza cookies para una buena funcionalidad. Dependiendo de las preferencias elegidas por los usuarios, UPBIRK puede usar otros tipos de cookies para facilitar el seguimiento de preferencias, datos de tráfico, y también para promover aspectos. Para obtener más detalles, es necesario revisar la Política de Cookies. </p>
                    <p className="fs-7 text-dark m-1">- Información obtenida a través de terceros públicos o privados: UPBIRK buscará información pública sobre personas y empresas y luego recopilará y procesará estos datos por interés comercial legítimo, de acuerdo con la Política de Privacidad y Protección de Datos. </p>
                    <p className="fs-7 text-dark m-1">- Debe tenerse en cuenta que cada vez que el usuario de UPBIRK divulgue voluntariamente información personal u organizacional en el entorno de comunicación en línea (como el cuadro de chat, los foros en línea, las páginas de redes sociales y los grupos de discusión), la información divulgada puede ser fácilmente recopilada y utilizada por personas no autorizadas. </p>
                    <p className="fs-7 text-dark m-1">-AUNQUE LA EMPRESA TOMA TODAS LAS MEDIDAS RAZONABLES IMPUESTAS POR LA LEGISLACIÓN APLICABLE PARA PROTEGER LOS DATOS PERSONALES DE LOS USUARIOS, NO PUEDE ASEGURAR O GARANTIZAR LA SEGURIDAD DE TODO TIPO DE INFORMACIÓN. POR LO TANTO, LA TRANSMISIÓN DE INFORMACIÓN EN UN ENTORNO ADECUADO SE REALIZA BAJO EL PROPIO RIESGO DEL USUARIO. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Corrección de la información proporcionada por los usuarios </h5>
                    <p className="fs-7 text-dark m-1">- Al completar los formularios existentes en UPBIRK (formulario de registro, cuadro de chat, detalles de pago), los usuarios están obligados a comunicar a la Empresa datos reales y válidos sobre sus cuentas personales u organizacionales. Además, al usar UPBIRK, cada usuario se compromete a que puede transmitir los mensajes cuando se completen los formularios y expresar su consentimiento válido (si corresponde), libremente y sin necesidad de aprobación previa de un tercero no obtenido previamente por el usuario respectivo. </p>
                    <p className="fs-7 text-dark m-1">- Si los datos proporcionados sufrieran algún cambio, los usuarios están obligados a comunicar este aspecto de inmediato a la Empresa, para que pueda cumplir con sus obligaciones legales y contractuales (por ejemplo, responder a las solicitudes enviadas por el usuario al acceder al cuadro de chat o al completar su perfil personal).  </p>
                  </div>
                  <div className="pb-5">
                    <h5>Modificación de las condiciones aplicables </h5>
                    <p className="fs-7 text-dark m-1">- EL ESTUDIO DE LOS TÉRMINOS Y CONDICIONES, ASÍ COMO DE LA POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS Y LA POLÍTICA DE COOKIES, DEBE SER ASEGURADO POR CADA USUARIO CADA VEZ QUE ACCEDA A UPBIRK Y ANTES DE PROPORCIONAR CUALQUIER DATO A LA EMPRESA, YA QUE PUEDEN APARECER CAMBIOS.  </p>
                    <p className="fs-7 text-dark m-1">- La Empresa se reserva el derecho de modificar/actualizar el contenido de UPBIRK, incluidas las políticas a las que se hace referencia, a su exclusivo criterio, en cualquier momento y por cualquier motivo (incluidos, entre otros, los cambios legislativos o jurisprudenciales que puedan afectar a las consecuencias publicadas en UPBIRK). La revisión de todas las políticas en línea de la Empresa en el futuro se señalará modificando la fecha de "Última actualización" en la parte superior de esta política. Después de que se publique la política actualizada, el acceso a UPBIRK representará la aceptación por parte del usuario de esas condiciones actualizadas.  </p>
                    <p className="fs-7 text-dark m-1">- Sin embargo, si hay cambios significativos que puedan afectar los derechos y libertades de los visitantes, se informará sobre esos cambios mediante indicaciones fácilmente visibles publicadas en UPBIRK (pop-ups) o mediante el envío de correos electrónicos a las direcciones proporcionadas (si corresponde). Dichos cambios significativos entrarán en vigor para los usuarios a partir de los 15 días posteriores a la publicación de la notificación emergente o al envío del correo electrónico (la forma en que se proporciona la información será decidida por la Empresa, caso por caso). </p>
                    <p className="fs-7 text-dark m-1">- Sin embargo, independientemente del alcance del cambio, la responsabilidad de verificar el contenido de UPBIRK (incluidos los Términos y Condiciones, así como las políticas mostradas), para estar al día con la última versión, será exclusivamente responsabilidad del usuario. Por lo tanto, EL ESTUDIO DE LOS TÉRMINOS Y CONDICIONES, ASÍ COMO DE LA POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS Y DE LA POLÍTICA DE COOKIES, DEBE SER REALIZADO POR LOS USUARIOS CADA VEZ QUE ACCEDAN A UPBIRK, Y ANTES DE REALIZAR CUALQUIER REGISTRO O PROPORCIONAR DATOS, YA QUE PUEDEN APARECER CAMBIOS.  </p>
                    <p className="fs-7 text-dark m-1">- La Empresa tiene el derecho exclusivo y gratuito de limitar/rechazar el acceso a UPBIRK (sin aplicar medidas discriminatorias y solo cumpliendo con las disposiciones legales), pero también de suspender o incluso cerrar el Sitio o la Aplicación de UPBIRK con efecto inmediato, así como de modificar su funcionalidad. </p>
                    <p className="fs-7 text-dark m-1">- Cualquier modificación de los precios puede ser realizada libremente por la Empresa, en cualquier momento. Sin embargo, esto no afectará de ninguna manera a los usuarios que ya hayan pagado por los servicios (no se les cobrará adicionalmente si el precio aumenta, ni recibirán ningún reembolso si el precio disminuye). Por lo tanto, cualquier cambio de precios aplicados por la Empresa entrará en vigor solo a partir del siguiente período de facturación de la suscripción (mes o año) después de la fecha de modificación respectiva. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Sitios web/aplicaciones de terceros </h5>
                    <p className="fs-7 text-dark m-1">- UPBIRK puede contener enlaces o referencias a sitios web/aplicaciones de otras empresas. Los sitios web/aplicaciones respectivas pueden contener términos y condiciones o políticas diferentes a los de la Empresa o pueden tener medidas de seguridad más bajas. ACCEDER A ESOS ENLACES Y ACCEDER IMPLÍCITAMENTE AL SITIO WEB/APLICACIÓN DEL TERCERO RESPECTIVO, SERÁ A CARGO EXCLUSIVO DEL USUARIO. </p>
                    <p className="fs-7 text-dark m-1">- Además, el contenido de UPBIRK puede incluir secciones de publicidad en las que se mostrarán mensajes publicitarios de terceros. La Empresa no se hace responsable de ninguna manera del contenido proporcionado por terceros, ya sea publicitario o no, ni de la forma en que esos terceros entienden cumplir con sus obligaciones legales. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Responsabilidad de los usuarios </h5>
                    <p className="fs-7 text-dark m-1">- Los usuarios son los únicos responsables de seleccionar, obtener, configurar y mantener el equipo utilizado para acceder a UPBIRK. </p>
                    <p className="fs-7 text-dark m-1">- Por lo tanto, la Empresa no ofrece ninguna garantía con respecto a aspectos relacionados, entre otros: la compatibilidad del Sitio/Aplicación con el equipo duro y blando utilizado por los usuarios; los colores o la claridad de las imágenes tomadas de UPBIRK, los efectos del uso de UPBIRK (incluida pero no limitada a la generación de virus), así como el funcionamiento adecuado de UPBIRK. Sin embargo, la responsabilidad de la Empresa por el procesamiento de los derechos personales no se excluye por esta cláusula y no se reduce por debajo del mínimo permitido por la legislación específica en este campo. </p>
                    <p className="fs-7 text-dark m-1">- La inserción de referencias y enlaces que dirijan a UPBIRK, la copia, modificación, transmisión, publicación o uso de cualquier manera de la información indicada en UPBIRK, está prohibida sin el permiso expreso por escrito de la Empresa. </p>
                    <p className="fs-7 text-dark m-1">- Además, los usuarios deben utilizar UPBIRK solo para actividades lícitas y no infringir los derechos de terceros. Tampoco deben presentar documentos o solicitudes que afirmen ser de otra persona, ni tomar ninguna medida que pueda afectar la integridad de UPBIRK o los datos contenidos en él (incluidos, pero no limitados a virus, eliminación o modificación de información, abusos electrónicos, fraude, uso de datos personales sin cumplir con la ley aplicable).  </p>
                    <p className="fs-7 text-dark m-1">- El incumplimiento de las obligaciones establecidas en este documento, así como cualquier otra obligación establecida por la ley para los usuarios del sitio web, conllevará su responsabilidad y la obligación de cubrir los daños generados por la acción culposa. Además, la Empresa en ningún caso será responsable de ninguna pérdida de uso, contratos, datos, ingresos o ganancias (independientemente de si se consideran reclamos directos o no) o cualquier pérdida, daño o gasto sufrido por los usuarios basado en o en conexión con UPBIRK. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Seguridad </h5>
                    <p className="fs-7 text-dark m-1">- UPBIRK está protegido por sistemas de seguridad competitivos. Como la mayoría de los sitios web y aplicaciones web, no pueden ser libres de vulnerabilidades. </p>
                    <p className="fs-7 text-dark m-1">- En consecuencia, la información transmitida por los usuarios utilizando UPBIRK puede estar sujeta a actividades que conduzcan a una violación de la confidencialidad y seguridad que van más allá del control directo de la Empresa (como el envío de mensajes de spam, virus utilizados en equipos, robo de direcciones IP, acceso no autorizado a correo electrónico) y para los cuales la Empresa no asume ninguna responsabilidad, aunque intenta evitar tales eventos desagradables.  </p>
                  </div>
                  <div className="pb-5">
                    <h5>Confidencialidad  </h5>
                    <p className="fs-7 text-dark m-1">- La confidencialidad de los datos de los usuarios es un aspecto importante para la Empresa. </p>
                    <p className="fs-7 text-dark m-1">- Nuestra Política de Privacidad y Protección de Datos forma parte integral de estos Términos y Condiciones y puede ser consultada por el usuario en cualquier momento. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Ley aplicable</h5>
                    <p className="fs-7 text-dark m-1">- Los derechos y obligaciones de las partes (es decir, los visitantes y la Empresa), así como todos los efectos legales que produce la actividad de UPBIRK, serán interpretados y regidos por la ley rumana. </p>
                    <p className="fs-7 text-dark m-1">- Cualquier disputa será llevada para su resolución ante los tribunales de derecho común de la sede de la Empresa. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Fuerza mayor </h5>
                    <p className="fs-7 text-dark m-1">- La Empresa no será responsable por el incumplimiento de las obligaciones asumidas, si dicho incumplimiento en tiempo y/o forma, total o parcial, es causado por un evento de fuerza mayor. Las partes acuerdan que dicho evento puede ser la infección del Sitio o la Aplicación o de la plataforma de TI detrás de ella, por razones que no pertenecen a una acción de la Empresa. No se necesita un certificado emitido por una autoridad al respecto, pero se requiere informar (por correo electrónico o mediante publicación en el Sitio y la Aplicación) a las personas afectadas. En la medida en que la ley aplicable a los datos personales establezca otras obligaciones en tales casos, la Empresa cumplirá con las condiciones legales respectivas. </p>
                  </div>
                  <div className="pb-5">
                    <h5>Disposiciones finales </h5>
                    <p className="fs-7 text-dark m-1">- Esta política se aplica a la Empresa y a los usuarios de UPBIRK (ya sea que estén registrados o no). </p>
                    <p className="fs-7 text-dark m-1">- Si alguna de las disposiciones anteriores se considera inválida o de otra manera inaplicable, la aplicabilidad de las disposiciones restantes no se verá afectada por ello. </p>
                    <p className="fs-7 text-dark m-1">- Los términos establecen el entendimiento completo entre los usuarios y la Empresa respecto al Sitio y la Aplicación, y reemplaza cualquier acuerdo previo entre los usuarios y la Empresa (incluidas, pero no limitadas a, versiones anteriores de estos términos). </p>
                    <p className="fs-7 text-dark m-1">- Este documento es parte del conjunto de políticas de seguridad de la Empresa. Otras políticas pueden aplicarse a los temas abordados en este documento y se revisarán según necesidades específicas. </p>
                  </div>
                </div>
                {/*  .nl-entry  */}
              </div>
              {/*  .nl-entry-wrap  */}
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Terms Section End  */}

      {/*  CTA Section Start  */}
      <NioSection>
        <NioSection.Content>
          <CallToAction />
        </NioSection.Content>
      </NioSection>
      {/*  CTA Section End  */}

      {/*  CTA Section End  */}



    </AppLayout>
  )
}

export default index;