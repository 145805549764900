import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'

// components
import NioIcon from '../../../NioIcon/NioIcon';
import NioField from '../../../NioField/NioField';
import NioCard from '../../../Cards/NioCard/NioCard';
import NioButton from '../../../NioButton/NioButton';
import NioToast from '../../../NioToaster/NioToaster';


export default function ContactForm() {

  const [showToast, setShowToast] = useState(false);
  const [toastContent, setToastContent] = useState(null);

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      mobile_number: '',
      job_title: '',
      cf_topic: '',
      cf_company: '',
      cf_company_type: '',
      cf_message: '',
    },
  });


  const handleSendCmr = async (formData) => {
    const baseURL = "https://elb.syrott.com/v2/dev/public/landing/upbirk/contact-us";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData)
    };
    return await fetch(baseURL, requestOptions).then((response) => {
      const status = response?.status === 200;
      return status
    })
  };

  const onSubmit = async (data, e) => {
    // Prevent default form submission behavior
    e.preventDefault();

    // Check if there are errors in the form
    if (Object.keys(errors).length === 0) {
      const result = await handleSendCmr(data)

      const variant = result ? "success" : "error";
      const title = result ? "Mensaje Enviado" : "Error correo ya resgistrado";
      const content = result ? "Gracias, te contactaremos a la brevedad" : "Ingresa otro correo"
      setToastContent({ variant, title, content })
    }
  };


  // Classes 
  const fieldWrapper = classNames({
    "form-control-wrap": true
  });

  useEffect(() => {
    if (toastContent) {
      setShowToast(true);
      setTimeout(() => {
        reset();
        setShowToast(false);
        setToastContent(null)
      }, 10000);
    }

  }, [toastContent])

  return (
    <>
      <NioCard className="rounded-2 border-4">
        <NioCard.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-4 g-lg-5">
              <Col xs="12">
                <NioField>
                  <NioField.Label label="¿En cual tema estas interesado?" htmlFor="cf_topic" />
                  <Form.Select placeholder="Escoja tema" id='cf_topic' isInvalid={!!errors.cf_topic} {...register("cf_topic", { required: 'El campo es requerido' })}>
                    <option defaultValue>Escoja el tema</option>
                    <option id='informe'>Informe de error</option>
                    <option id='solicitud'>Solicitud de función</option>
                    <option id='consulta'>Consultas generales</option>
                    <option id='precio'>Precios y facturación</option>
                  </Form.Select>
                  {
                    errors?.cf_topic &&
                    <Form.Control.Feedback type="invalid" tooltip>{errors?.cf_topic.message}</Form.Control.Feedback>
                  }
                </NioField>
              </Col>
              <Col xs="6">
                <NioField>
                  <NioField.Label label="Nombre" htmlFor="first_name" />
                  <NioField.Input id="first_name" placeholder="Escriba su nombre"
                    isInvalid={!!errors.first_name} {...register("first_name", { required: 'El campo es requerido' })} />
                  {
                    errors?.first_name &&
                    <Form.Control.Feedback type="invalid" tooltip>{errors?.first_name.message}</Form.Control.Feedback>
                  }
                </NioField>
              </Col>
              <Col xs="6">
                <NioField>
                  <NioField.Label label="Apellido" htmlFor="last_name" />
                  <NioField.Input id="last_name" placeholder="Escriba su apellido"
                    isInvalid={!!errors.last_name} {...register("last_name", { required: 'El campo es requerido' })} />
                  {
                    errors?.last_name &&
                    <Form.Control.Feedback type="invalid" tooltip>{errors?.last_name.message}</Form.Control.Feedback>
                  }
                </NioField>
              </Col>
              <Col xs="6">
                <NioField>
                  <NioField.Label label="Correo" htmlFor="email" />
                  <NioField.Input type="email" id="email" placeholder="ejemplo@ejemplo.com" isInvalid={!!errors.email}   {...register("email", {
                    required: "El campo es requerido",
                    pattern: {
                      value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "Correo invalido"
                    }
                  })} />
                  {
                    errors?.email &&
                    <Form.Control.Feedback type="invalid" tooltip>{errors?.email.message}</Form.Control.Feedback>
                  }
                </NioField>
              </Col>
              <Col xs="6">
                <NioField>
                  <NioField.Label label="Organización" htmlFor="cf_company" />
                  <NioField.Input id="cf_company" placeholder="Escriba Nombre Empresa"
                    isInvalid={!!errors.cf_company} {...register("cf_company", { required: 'El campo es requerido' })} />
                  {
                    errors?.cf_company &&
                    <Form.Control.Feedback type="invalid" tooltip>{errors?.cf_company.message}</Form.Control.Feedback>
                  }
                </NioField>
              </Col>
              <Col xs="6">
                <NioField>
                  <NioField.Label label="Tipo Organización" htmlFor="cf_company_type" />
                  <Form.Select id="cf_company_type" placeholder="Escriba Tipo Empresa"
                    isInvalid={!!errors.cf_company_type} {...register("cf_company_type", { required: 'El campo es requerido' })} >
                    <option defaultValue>Escoja el tipo</option>
                    <option id='empresa'>Empresa</option>
                    <option id='universidad'>universidad</option>
                    <option id='negocio'>Incubadora de Negocios</option>
                      </Form.Select>
                  {
                    errors?.cf_company_type &&
                    <Form.Control.Feedback type="invalid" tooltip>{errors?.cf_company_type.message}</Form.Control.Feedback>
                  }
                </NioField>
              </Col>
              <Col xs="6">
                <NioField>
                  <NioField.Label label="Cargo" htmlFor="job_title" />
                  <NioField.Input id="job_title" placeholder="Escriba Cargo"
                    isInvalid={!!errors.job_title} {...register("job_title", { required: 'El campo es requerido' })} />
                  {
                    errors?.job_title &&
                    <Form.Control.Feedback type="invalid" tooltip>{errors?.job_title.message}</Form.Control.Feedback>
                  }
                </NioField>
              </Col>
              <Col lg="6">
                <NioField>
                  <NioField.Label label="Telefono" htmlFor="mobile_number" />
                  <NioField.Input type="number" id="mobile_number" placeholder="+56912345678" isInvalid={!!errors.mobile_number} {...register("mobile_number", {
                    required: 'El campo es requerido'
                  })} />
                  {
                    errors?.mobile_number &&
                    <Form.Control.Feedback type="invalid" tooltip>{errors?.mobile_number.message}</Form.Control.Feedback>
                  }
                </NioField>
              </Col>
              <Col xs="12">
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label">Dejanos tu mensaje</label>
                  </div>
                  <div className={fieldWrapper}>
                    <Form.Control
                      rows="6"
                      as="textarea"
                      isInvalid={!!errors.cf_message}
                      {...register("cf_message", { required: 'El campo es requerido' })}
                      placeholder="Escribe tu mensaje"
                    />
                    {
                      errors?.cf_message &&
                      <Form.Control.Feedback type="invalid" tooltip>{errors?.cf_message.message}</Form.Control.Feedback>
                    }
                  </div>
                </div>
              </Col>
              <Col lg="12">
                <NioField>
                  <div className="d-flex flex-wrap align-items-start justify-content-start   mt-3">
                    <NioField.Check id="check" label="" isInvalid={!!errors.caja} {...register("caja", {
                      required: 'El campo es requerido'
                    })}
                    />
                    <p className='fs-8'>Acepto que Upbirk me envíe comunicaciones sobre productos, servicios o contenidos relevantes. Puedo darme de baja en cualquier momento.</p>
                  </div>
                  {
                    errors?.caja &&
                    <Form.Control.Feedback type="invalid" tooltip>{errors?.caja.message}</Form.Control.Feedback>
                  }
                </NioField>
                <NioField>
                  <div className="d-flex flex-wrap align-items-start justify-content-start   mt-3">
                    <NioField.Check id="check" label="" isInvalid={!!errors.caja2} {...register("caja2", {
                      required: 'El campo es requerido'
                    })}
                    />
                    <p className='fs-8'>Acepto que Upbirk procese y almacene mis datos personales, de acuerdo con la <a href='/politica'>política de privacidad.</a></p>
                  </div>
                  {
                    errors?.caja2 &&
                    <Form.Control.Feedback type="invalid" tooltip>{errors?.caja2.message}</Form.Control.Feedback>
                  }
                </NioField>
              </Col>
              <Col xs="12">
                <div className="form-group">
                  <NioButton type="submit" className="btn" label="Enviar mensaje" style={{ backgroundColor: "#3680BC" }} />
                </div>
              </Col>
            </Row>
          </Form>
          <NioToast show={showToast} setShow={setShowToast} variant={toastContent?.variant}>
            <div>
              <span className="nl-toast-icon">
                <NioIcon name={toastContent?.variant} />
              </span>
            </div>
            <div className="nl-toast-info w-100 text-start">
              <h6 className="fs-7 mb-0">{toastContent?.title}</h6>
              <p className="fs-9">{toastContent?.content}</p>
            </div>
          </NioToast>
        </NioCard.Body>
      </NioCard>
    </>
  )
}
