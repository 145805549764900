import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

// layouts 
import AppLayout from '../../layouts/AppLayout/AppLayout';
import CallToAction from '../../components/PageComponents/SectionComponents/CallToAction/CallToAction';
import FaqContent from '../../components/PageComponents/InnerPages/HelpCenter/FaqContent/FaqContent';

// components
import { NioSection, NioIcon} from '../../components';

function index() {
  return (
    <AppLayout title="Pricing" layoutClass="3">

      {/*  Compare-Plans Section Start  */}
      
      <Helmet>
        <title>Pricing: Elige el plan que mas te acomode | Upbirk</title>
        <meta name='description' content='Descubre el plan que se adapta perfectamente a tus necesidades como emprendedor y accede a todas las herramientas y ventajas que necesitas en tu proyecto.'/>
        <link rel='canonical' href='/Pricing'/>
      </Helmet>
    
        <section className="nl-section nl-banner nl-banner-analytics overflow-hidden bg-primary-gradient-soft">
        <div className="nl-banner-wrap pb-0 pb-md-8 overflow-hidden pb-8">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="9">
                <div className="nl-banner-content text-center">
                  <h3 className="display-4 m-0 pb-5">Selecciona el <span style={{color:"#3680BC"}}>Mejor Plan </span>para Emprendedores</h3>
                  <p className="fs-5">Descubre el plan que se adapta perfectamente a tus necesidades como emprendedor y accede a todas las herramientas y ventajas que necesitas en tu proyecto.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <NioSection className="pt-0 pb-8">
          <NioSection.Content>
            <div className="nl-compare table-responsive p-0">
              <table className="table">
                <thead>
                  <tr>
                    <th className="nl-compare-col lg empty-col"></th>
                    <th className="nl-compare-col empty-col center" >
                      <div className="nl-compare-content">
                        <div className="fs-6 text-uppercase fw-semibold text-gray-700 mb-3 fw-bold" >Plan Free</div>
                        {/* <div className="amount h3 mb-4">$99</div>                       */}
                      </div>
                    </th>
                    <th className="nl-compare-col active empty-col center ">
                      <div className="nl-compare-content">
                        <div className="fs-6 text-uppercase fw-semibold text-gray-700 mb-3 fw-bold">Plan Premium</div>
                        {/* <div className="amount h3 mb-4">$199</div>                        */}
                      </div>
                    </th>                    
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="nl-compare-col lg">
                      <div className="nl-compare-content">
                        <p className="fs-6 mb-0 text-nowrap text-gray-700 " style={{marginLeft:"50px"}}>Creación Básica de Proyecto</p>
                      </div>
                    </td>
                    <td className="nl-compare-col center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                      </div>
                    </td>
                    <td className="nl-compare-col active center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                      </div>
                    </td>
                    
                  </tr>
                  <tr>
                    <td className="nl-compare-col lg">
                      <div className="nl-compare-content">
                        <p className="fs-6 mb-0 text-nowrap text-gray-700" style={{marginLeft:"50px"}}>Vincularte con Actores</p>
                      </div>
                    </td>
                    <td className="nl-compare-col center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                      </div>
                    </td>
                    <td className="nl-compare-col active center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                      </div>
                    </td>
                   
                  </tr>
                  <tr>
                    <td className="nl-compare-col lg">
                      <div className="nl-compare-content">
                        <p className="fs-6 mb-0 text-nowrap text-gray-700" style={{marginLeft:"50px"}}>Participación en Programas</p>
                      </div>
                    </td>
                    <td className="nl-compare-col center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                      </div>
                    </td>
                    <td className="nl-compare-col active center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                      </div>
                    </td>
              
                  </tr>
                 
                  <tr>
                    <td className="nl-compare-col lg">
                      <div className="nl-compare-content">
                        <p className="fs-6 mb-0 text-nowrap text-gray-700" style={{marginLeft:"50px"}}>Completar Reportes</p>
                      </div>
                    </td>
                    <td className="nl-compare-col center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                      </div>
                    </td>
                    <td className="nl-compare-col active center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nl-compare-col lg">
                      <div className="nl-compare-content">
                        <p className="fs-6 mb-0 text-nowrap text-gray-700" style={{marginLeft:"50px"}}>Número de Proyectos</p>
                      </div>
                    </td>
                    <td className="nl-compare-col center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle" className="text-primary fs-4" />
                        <p className='fs-6'>Uno</p>
                      </div>
                    </td>
                    <td className="nl-compare-col active center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                        <p className='fs-6'>Ilimitado</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nl-compare-col lg">
                      <div className="nl-compare-content">
                        <p className="fs-6 mb-0 text-nowrap text-gray-700" style={{marginLeft:"50px"}}>Frameworks Disponibles</p>
                      </div>
                    </td>
                    <td className="nl-compare-col center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle" className="text-primary fs-4" />
                        <p className='fs-6'>5 Frameworks</p>
                      </div>
                    </td>
                    <td className="nl-compare-col active center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                        <p className='fs-6'>Ilimitado</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nl-compare-col lg">
                      <div className="nl-compare-content">
                        <p className="fs-6 mb-0 text-nowrap text-gray-700" style={{marginLeft:"50px"}}>Versiones de Frameworks</p>
                      </div>
                    </td>
                    <td className="nl-compare-col center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle" className="text-primary fs-4" />
                        <p className='fs-6'>1 X Framework</p>
                      </div>
                    </td>
                    <td className="nl-compare-col active center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                        <p className='fs-6'>Ilimitado</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nl-compare-col lg">
                      <div className="nl-compare-content">
                        <p className="fs-6 mb-0 text-nowrap text-gray-700" style={{marginLeft:"50px"}}>Miembros por Proyecto</p>
                      </div>
                    </td>
                    <td className="nl-compare-col center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle" className="text-primary fs-4" />
                        <p className='fs-6'>1 Usuario</p>
                      </div>
                    </td>
                    <td className="nl-compare-col active center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                        <p className='fs-6'>Ilimitado</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nl-compare-col lg">
                      <div className="nl-compare-content">
                        <p className="fs-6 mb-0 text-nowrap text-gray-700" style={{marginLeft:"50px"}}>Vincular Frameworks a Profile</p>
                      </div>
                    </td>
                    <td className="nl-compare-col center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle" className="text-primary fs-4" />
                        <p className='fs-6'>2 Frameworks</p>
                      </div>
                    </td>
                    <td className="nl-compare-col active center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                        <p className='fs-6'>Ilimitado</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nl-compare-col lg">
                      <div className="nl-compare-content">
                        <p className="fs-6 mb-0 text-nowrap text-gray-700" style={{marginLeft:"50px"}}>Exportar Frameworks</p>
                      </div>
                    </td>
                    <td className="nl-compare-col center"></td>
                    <td className="nl-compare-col active center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nl-compare-col lg">
                      <div className="nl-compare-content">
                        <p className="fs-6 mb-0 text-nowrap text-gray-700" style={{marginLeft:"50px"}}>Descargar Data en Excel</p>
                      </div>
                    </td>
                    <td className="nl-compare-col center"></td>
                    <td className="nl-compare-col active center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nl-compare-col lg">
                      <div className="nl-compare-content">
                        <p className="fs-6 mb-0 text-nowrap text-gray-700" style={{marginLeft:"50px"}}>Asistente Virtual AMIRA</p>
                      </div>
                    </td>
                    <td className="nl-compare-col center"></td>
                    <td className="nl-compare-col active center">
                      <div className="nl-compare-content">
                        <NioIcon  name="check-circle-fill" className="text-primary fs-4" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nl-compare-col lg">
                      <div className="nl-compare-content">
                        <p className="fs-6 mb-0 text-nowrap text-gray-700" style={{marginLeft:"50px"}}>Eliminación Cuenta</p>
                      </div>
                    </td>
                    <td className="nl-compare-col center">
                      <div className="nl-compare-content">
                        
                        <p className='fs-6'>60 días sin actividad en plataforma</p>
                      </div>
                    </td>
                    <td className="nl-compare-col active center">
                      <div className="nl-compare-content">
                      <p className='fs-6'>Mientras sea usuario premium</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </NioSection.Content>
          </NioSection>
       

      {/*  Compare-Plans Section End  */}

      <NioSection className="pt-0 pb-8">
        <NioSection.Head alignX="center" className="pb-4">        
          <h2 className="nl-section-title m-0 pb-5"><span style={{color:"#3680BC"}}>Preguntas</span> Frecuentes</h2>
          <p>Encuentra respuestas detalladas a las interrogantes más comunes sobre nuestro producto y servicios en esta sección.</p>
        </NioSection.Head>
        <NioSection.Content>
          <Row className="gy-5 gy-lg-0 ">
            <Col lg="12">
              <FaqContent />
            </Col>           
          </Row>
        </NioSection.Content>
      </NioSection>

      {/*  Newsletter Section Start  */}
      <NioSection className="pt-0 pb-8">
        <NioSection.Content>
          <CallToAction />
        </NioSection.Content>
      </NioSection>
    </AppLayout>
  )
}

export default index;