import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';


// layout
import AppLayout from '../../layouts/AppLayout/AppLayout';

// components
import { NioButton, NioSection} from '../../components';

// section content 

import CallToAction from '../../components/PageComponents/SectionComponents/CallToAction/CallToAction';

function index() {

  return (
    <AppLayout title="Project Builder: Creador de proyectos, Powered by IA" layoutClass="3">

      <Helmet>
        <title>Project Builder: Creador de proyectos, Powered by IA</title>
        <meta name='description' content='Centraliza la información de tu proyecto de innovación y controla a quienes se las estás compartiendo de manera digital y 100% online'/>
        <link rel='canonical' href='/'/>
      </Helmet>

      <section className="nl-section nl-banner nl-banner-analytics overflow-hidden bg-primary-gradient-soft">     
        <div className="nl-banner-wrap pb-0 pb-md-8 overflow-hidden">
          <Container>
            <Row className="justify-content-center ">
              <Col lg="10" xl="8"> 
                <div className="nl-banner-content text-center">
                <h1 className="display-4 m-0 pb-5">La Información de tu <span style={{color:"#3680BC"}}>Startup </span> en un solo Lugar</h1>
                  <p className="fs-5"> Centraliza la información de tu proyecto de innovación y controla a quienes se las estás compartiendo de manera digital y 100% online</p>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="12" lg="10" xl="12" >
                <div className="pt-6 mt-md-2 pb-7 pb-md-0">
                  <div className="position-relative nl-banner-img nl-banner-img-s1">
                    <img className="thumb-base rounded-3" src="images/thumb/Frame 1372 (2).svg" alt="thumb-content"/>     
                    {/* <img className="thumb thumb-1 animate animate-shakeY animate-duration-12" src="images/cta/imagen2.svg"  alt="thumb-content" style={{paddingBottom:"20px", marginLeft:"20px"}}/>
                    <img className="thumb thumb-2 animate animate-shakeY animate-duration-12 animate-delay-1" src="images/thumb/Frame 1394.svg" alt="thumb-content" style={{width:"63%"}}/>                */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>  
      </section>

      <NioSection className="pb-8 pt-6">
        <NioSection.Content>
          <Row className="justify-content-center ">
            <Col  lg="10" xl="8">
              <div className="text-center">
                <h5 className="fw-normal text-gray-900 pb-5 mb-0">Tecnología utilizada por diversos actores del ecosistema en la región</h5>
                <Row className="gy-2 gy-lg-0 align-items-center justify-content-center">
                  {
                    ["quintil2","22-3", "33-6", "44", "55"]?.map((brand, idx) => (
                      <Col xs="4" sm="2" key={idx}>
                        <div className="nl-brand">
                          <Link to="#">
                            <img src={`images/brands/${brand}.png`} alt={`brand-${brand}`} style={{filter: "grayscale(100%)"}}/>
                          </Link>
                        </div>
                      </Col>
                    ))
                  }
                </Row>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>

      <section className="  ">
        <div className="pb-8">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="nl-banner-content text-center">
                  <h1 className="display-5 m-0 pb-5">Una nueva forma de idear, desarrollar y comunicar tu idea de <span style={{color:"#3680BC"}}>  negocios</span></h1>
                  <p className="fs-5">Somos una herramienta digital diseñada para simplificar y estandarizar la manera en que se crea un proyecto de innovación, ofreciendo al usuario un control de como esta información se comparte con los distintos stakeholders que están involucrados en el desarrollo de la idea de negocios. </p>
                </div>
              </Col>
            </Row>          
          </Container>
        </div>
      </section>

        <NioSection className="py-7 py-lg-90 pt-0 pb-0">
        <NioSection.Content className="d-flex flex-column gap-7">
          <Row className="align-items-start flex-lg-row-reverse justify-content-between">
            <Col lg="6" xl="5">
              <div className="nl-feature-overview-img text-center mb-5 mb-lg-0">
              <img src="images/cta/imagen1.svg" className="" alt="thumb" />

              </div>
            </Col>
            <Col lg="5">
              <div>
                <div className="nl-section-head pb-5">
                  <h2 className="nl-section-title">Un mundo de <span style={{color:"#3680BC"}}>Posibilidades</span></h2>
                </div>
                <ul className="nl-timeline d-flex flex-column gap-3 gap-md-5">
                  <li className="nl-timeline-item p-0">
                    <div className="nl-timeline-item-inner">   
                      <div className="nl-timeline-content mt-n1" style={{textAlign:"justify"}}>
                        <p>Nuestro sistema cuenta con la mayoría de las herramientas y frameworks que suelen ser solicitadas por los distintos stakeholders con los cuales suele estar conectado un proyecto de innovación a lo largo de su ciclo de vida, los cuales han sido digitalizados para que puedas trabajarlos en un solo lugar.</p>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul className="d-flex gap-3 flex-wrap align-items-center pt-5 pt-lg-5">
                  <li>
                    <NioButton href="/frameworks" className="btn" label="Más Información" style={{backgroundColor:"#3680BC"}}/>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="align-items-start justify-content-between">
            <Col lg="6" xl="5">
              <div className="nl-feature-overview-img text-center mb-5 mb-lg-0">
              <img src="images/cta/imagen2.svg" className="" alt="thumb" />
              
              </div>
            </Col>
            <Col lg="5">
              <div>
                <div className="nl-section-head pb-5">
                  <h2 className="nl-section-title">Potenciado por <span style={{color:"#3680BC"}}>Inteligencia Artificial</span></h2>
                </div>
                <ul className="nl-timeline d-flex flex-column gap-3 gap-md-5">
                  <li className="nl-timeline-item p-0">
                    <div className="nl-timeline-item-inner">
                      <div className="nl-timeline-content mt-n1" style={{textAlign:"justify"}}>
                        <p>Tendrás a disposición una asistente virtual que te ayuda a comprender a como debes completar los aspectos de tu negocio que deseas trabajar en el sistema, así como consejos y recomendaciones útiles de como debes completar las diferentes secciones de cada framework de tu proyecto de innovación.</p>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul className="d-flex gap-3 flex-wrap align-items-center pt-5 pt-lg-5">
                  <li>
                    <NioButton href="/amira" className="btn" label="Más Información" style={{backgroundColor:"#3680BC"}}/>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="align-items-start flex-lg-row-reverse justify-content-between">
            <Col lg="6" xl="5">
              <div className="nl-feature-overview-img text-center mb-5 mb-lg-0">
              <img src="images/cta/imagen3.svg" className="" alt="thumb" />
                
              </div>
            </Col>
            <Col lg="5">
              <div>
                <div className="nl-section-head pb-5">
                  <h2 className="nl-section-title">Donde tu decides como <span style={{color:"#3680BC"}}>Compartirla</span></h2>
                </div>
                <ul className="nl-timeline d-flex flex-column gap-3 gap-md-5">
                  <li className="nl-timeline-item p-0">
                    <div className="nl-timeline-item-inner">
                      <div className="nl-timeline-content mt-n1" style={{textAlign:"justify"}}>
                        <p>Gestiona en un solo sistema la manera en que compartes la información clave del sistema, sea vinculando tus ideas a alguna ruta de innovación de alguno de los actores del ecosistema con los cuales estás conectado en el sistema, descargándolo en formato PDF o compartiendolo con tu equipo de trabajo.</p>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul className="d-flex gap-3 flex-wrap align-items-center pt-5 pt-lg-5">
                  <li>
                    <NioButton href="/Features" className="btn" label="Más Información" style={{backgroundColor:"#3680BC"}}/>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>

      <NioSection className=" pt-8 pb-8">
        <NioSection.Content>
          <CallToAction />
        </NioSection.Content>
      </NioSection>
    </AppLayout >
  )
}

export default index