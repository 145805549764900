import React from 'react'
import { Col, Row } from 'react-bootstrap'

// components
import NioButton from '../../../NioButton/NioButton'

import NioSection from '../../../NioSection/NioSection'


export default function CallToAction() {
  return (
    <NioSection className="p-0">
    <NioSection.Content className="nl-section-content px-5 px-xl-0 py-7 position-relative bg-dark  rounded-4 mb-0" masks={["shape-31"]}>
      <Row className="position-relative gy-5 gy-lg-7 flex-column align-items-center justify-content-center" >
        <Col xl="8">
          <div className="text-center">
            <h2 className="text-white m-0 pb-5">Únete a la Versión Beta</h2>
            <p className="text-white fs-7 opacity-75 m-0">
            Regístrate ahora llenando nuestro formulario de inscripción y sé uno de los primeros en experimentar todas las nuevas funcionalidades que entrega nuestro sistema
            </p>
        <NioButton href="https://new.upbirk.com/" className="btn mt-3" label="Regístrate" target="_blank" style={{backgroundColor:"#3680BC"}}/>
          </div>
        </Col>
      </Row>
    </NioSection.Content>
  </NioSection>
  )
}
